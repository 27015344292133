<template>
<span>
  <b-modal id="modal-upload" hide-footer size="xl" class="animationOpacity">
    <b-row class="upload">
      <div class="modalLateralMidiaFundo" v-if="openModalMidia">
        <div class="modalLateralMidia animationMenu2 animationOpacity">
          <div class="spaceClose" @click="closeModalItem">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="midiaSelected">
            <b-form-group label="Preview" label-for="name-module">
              <div class="spaceVideoModal">
                <video
                  ref="videoPlayer"
                  v-if="midiaSelectedModal.mime === 'youtube'"
                  class="video-js widthVideoPlayer"
                  :tech-order="'youtube'"
                  :autoplay="false"
                  :controls="true"
                >
                  <source
                    :src="
                      'https://www.youtube.com/watch?v=' +
                      midiaSelectedModal.id.videoId
                    "
                    :type="'video/youtube'"
                  />
                </video>
                <video
                  ref="videoPlayer"
                  v-if="midiaSelectedModal.mime === 'vimeo'"
                  class="video-js widthVideoPlayer"
                  :tech-order="'vimeo'"
                  :autoplay="false"
                  :controls="true"
                >
                  <source
                    :src="midiaSelectedModal.link"
                    :type="'video/vimeo'"
                  />
                </video>
              </div>
              <div
                class="spaceImgModal"
                v-if="
                  midiaSelectedModal.mime !== 'youtube' &&
                  midiaSelectedModal.mime !== 'vimeo'
                "
              >
                <iframe
                  v-if="midiaSelectedModal.mime === 'application/pdf'"
                  class="imgMidia"
                  height="100%"
                  width="100%"
                  :src="midiaSelectedModal.cdn_url"
                ></iframe>
                <iframe
                  class="imagem"
                  height="100%"
                  v-else-if="midiaSelectedModal.mime === 'video/mp4' ||
                  midiaSelectedModal.mime === 'video/x-matroska' ||
                  midiaSelectedModal.mime === 'video/quicktime'
                  "
                  width="100%"
                  :src="midiaSelectedModal.cdn_url"
                  style="
                    border: 0;
                    pointer-events: all;
                    height: 25vh !important;
                  "
                ></iframe>
                <img
                  loading="lazy"
                  class="imgMidia 1"
                  v-else-if="
                    (midiaSelectedModal.mime === 'image/jpeg' ||
                      midiaSelectedModal.mime === 'image/jpg' ||
                      midiaSelectedModal.mime === 'image/webp' ||
                      midiaSelectedModal.mime === 'image/png') &&
                    midiaSelectedModal.small_horizontal !== null &&
                    midiaSelectedModal.small_horizontal !== undefined
                  "
                  :srcset="
                    midiaSelectedModal.small_horizontal +
                    ' 200w, ' +
                    midiaSelectedModal.medium_horizontal +
                    ' 300w, ' +
                    midiaSelectedModal.large_horizontal +
                    ' 1000w'
                  "
                  :src="midiaSelectedModal.cdn_url"
                  :alt="midiaSelectedModal.title"
                />
                <img
                  loading="lazy"
                  class="imgMidia 2"
                  v-else-if="midiaSelectedModal.mime === 'application/zip'"
                  src="https://app.greenn.club/zip.png"
                  :alt="midiaSelectedModal.title"
                />
                <img
                  loading="lazy"
                  class="imgMidia 3"
                  v-else-if="
                    midiaSelectedModal.mime === 'application/msword' ||
                    midiaSelectedModal.mime ===
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  "
                  src="https://app.greenn.club/doc.png"
                  :alt="media.title"
                />
                <img
                  loading="lazy"
                  class="imgMidia 4"
                  v-else-if="
                    midiaSelectedModal.mime === 'application/vnd.ms-excel' ||
                    midiaSelectedModal.mime ===
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  "
                  src="https://app.greenn.club/xls.png"
                  :alt="media.title"
                />
                <div v-else-if="midiaSelectedModal.mime === 'audio/mpeg'">
                  <img
                    loading="lazy"
                    class="imgMidiaAudio 4 mb-2"
                    src="@/assets/img/audio.png"
                    :alt="media.title"
                  />
                  <audio controls>
                    <source
                      :src="midiaSelectedModal.cdn_url"
                      type="audio/mpeg"
                    />
                  </audio>
                </div>
                <img
                  loading="lazy"
                  class="imgMidia 5"
                  v-else-if="
                    ((midiaSelectedModal.mime === 'image/jpeg' ||
                      midiaSelectedModal.mime === 'image/jpg' ||
                      midiaSelectedModal.mime === 'image/webp' ||
                      midiaSelectedModal.mime === 'image/png') &&
                      midiaSelectedModal.small_horizontal === null) ||
                    midiaSelectedModal.small_horizontal === undefined
                  "
                  :src="midiaSelectedModal.cdn_url"
                  :alt="midiaSelectedModal.title"
                />
              </div>
            </b-form-group>

            <b-form-group
              v-if="midiaSelectedModal.mime === 'youtube'"
              label="Nome"
              label-for="name-module"
            >
              <p class="textMidiaSelected">
                {{ midiaSelectedModal.snippet.title }}
              </p>
            </b-form-group>
            <b-form-group
              v-else-if="midiaSelectedModal.mime === 'vimeo'"
              label="Nome"
              label-for="name-module"
            >
              <p class="textMidiaSelected">{{ midiaSelectedModal.name }}</p>
            </b-form-group>
            <b-form-group
              v-else-if="midiaSelectedModal.mime === 'audio/mpeg'"
              label="Nome"
              label-for="name-module"
            >
              <b-form-input
                v-model="midiaSelectedModal.title"
                placeholder="Nome do Audio"
                class="name-audio"
              ></b-form-input>
              <button
                class="btn-upload mt-1 col-md-5 col-5 change-title"
                @click.prevent="saveTitle"
              >
                Salvar Titulo
              </button>
            </b-form-group>
            <b-form-group v-else label="Nome" label-for="name-module">
              <p class="textMidiaSelected">{{ midiaSelectedModal.title }}</p>
            </b-form-group>

            <b-form-group
              v-if="midiaSelectedModal.mime === 'youtube'"
              label="Tipo"
              label-for="name-module"
            >
              <p class="textMidiaSelected">YouTube</p>
            </b-form-group>
            <b-form-group
              v-else-if="midiaSelectedModal.mime === 'vimeo'"
              label="Tipo"
              label-for="name-module"
            >
              <p class="textMidiaSelected">Vimeo</p>
            </b-form-group>
            <b-form-group v-else label="Tipo" label-for="name-module">
              <p class="textMidiaSelected">{{ midiaSelectedModal.mime }}</p>
            </b-form-group>

            <div
              class="spaceInputs"
              v-if="midiaSelectedModal.mime === 'audio/mpeg'"
            >
              <b-form-group label="Capa do Audio" label-for="capa">
                <p class="textDimenssion">
                  Proporção Recomendada: 16:9 - Tamanho Recomendado: 1250x747
                </p>
                <div
                  class="btn-openModal"
                  @click="showModalUploadThumbAudio"
                  v-if="
                    midiaSelectedModal.cover === '' ||
                    midiaSelectedModal.cover === null
                  "
                >
                  <p class="text">Clique e faça o Upload da Capa do Audio</p>
                </div>
                <div
                  class="btn-openModalPreview"
                  @click="showModalUploadThumbAudio"
                  v-else
                >
                  <img
                    class="imagePreviewCapa animationOpacity"
                    :src="midiaSelectedModal.cover"
                    :alt="midiaSelectedModal.cover"
                  />
                </div>
              </b-form-group>
            </div>

            <b-form-group
              v-if="midiaSelectedModal.mime === 'youtube'"
              label="Data de Upload"
              label-for="name-module"
            >
              <p class="textMidiaSelected">
                {{
                  midiaSelectedModal.snippet.publishedAt
                    | moment("DD/MM/YYYY HH:mm:ss")
                }}
              </p>
            </b-form-group>
            <b-form-group
              v-else-if="midiaSelectedModal.mime === 'vimeo'"
              label="Data de Upload"
              label-for="name-module"
            >
              <p class="textMidiaSelected">
                {{
                  midiaSelectedModal.created_time
                    | moment("DD/MM/YYYY HH:mm:ss")
                }}
              </p>
            </b-form-group>
            <b-form-group v-else label="Data de Upload" label-for="name-module">
              <p class="textMidiaSelected">
                {{
                  midiaSelectedModal.updated_at | moment("DD/MM/YYYY HH:mm:ss")
                }}
              </p>
            </b-form-group>
            <div v-if="midiaSelectedModal.mime === 'video/mp4'" label-for="name-module" class="divSelectThumb">
              <input type="file" accept=".jpg, image/png" ref="fileInput" style="display: none;" @change="handleFileSelection">
              <a class="selectThumb" @click="selectThumb(midiaSelectedModal.id)">
                <div class="centerThumb">
                  <div>
                    <svg style="color: #81858e" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-file-earmark-arrow-up" viewBox="0 0 16 16"> <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707V11.5z" fill="#81858e"></path> <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" fill="#81858e"></path> </svg>
                  </div>
                  
                  <div class="textThumb">
                    <label class="text"> Fazer upload da Thumbnail</label>
                    <span class="textThumbLow">(apenas PNG ou JPG)</span>
                  </div>
                </div>
              </a>
            </div>
            <b-form-group
              v-if="midiaSelectedModal.mime === 'application/pdf'"
              label="Arquivo Protegido"
              label-for="name-module"
            >
              <toggle-button
                id="toggleBlock"
                v-model="midiaSelectedModal.protected"
                @input="updateBlocked(midiaSelectedModal)"
                :sync="true"
              />
            </b-form-group>
            <b-tooltip
              custom-class="tooltipXX"
              target="toggleBlock"
              title="Tera Nome e CPF do Aluno no Documento"
            ></b-tooltip>

            <b-form-group
              v-if="midiaSelectedModal.mime === 'application/pdf'"
              label="Campos Personalizados"
              label-for="name-module"
            >
              <toggle-button
                id="toggleBlock"
                v-model="midiaSelectedModal.use_custom_fields"
                @input="updateCustoms(midiaSelectedModal)"
                :sync="true"
              />
            </b-form-group>

            <b-form-group
              v-if="midiaSelectedModal.use_custom_fields"
              label="Campos Personalizados"
              label-for="name-module"
            >
              <div>
                <multiselect
                  :custom-label="customLabel"
                  placeholder="Selecione um campo Personalizado!"
                  selectedLabel=""
                  deselectLabel="Pressione Enter"
                  :close-on-select="true"
                  selectLabel="Pressione Enter"
                  track-by="value"
                  :multiple="true"
                  :taggable="true"
                  v-model="customInputs"
                  :options="optionsLessonCategory"
                ></multiselect>
              </div>
            </b-form-group>
          </div>
          <div class="lineDiv mt-2 mb-3 col-md-12 col-12"></div>
          <div
            v-if="
              (media === true && midiaSelectedModal.disk !== 'panda') ||
              selectInUpload
            "
          >
            <button
              class="btn-upload col-md-12 col-12"
              @click.prevent="SelectMidia"
            >
              Selecionar midia
              <svg
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.574707 8.77336C0.574707 9.20054 1.07577 9.43099 1.4001 9.15299L5.96514 5.2401C6.19795 5.04055 6.19795 4.68039 5.96514 4.48085L1.4001 0.567956C1.07577 0.289953 0.574707 0.520408 0.574707 0.947584L0.574707 8.77336Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
          <div v-if="midiaSelectedModal.mime === 'youtube'">
            <button
              class="btn-upload col-md-12 col-12"
              @click.prevent="addUrlYoutube"
            >
              Selecionar vídeo
              <svg
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.574707 8.77336C0.574707 9.20054 1.07577 9.43099 1.4001 9.15299L5.96514 5.2401C6.19795 5.04055 6.19795 4.68039 5.96514 4.48085L1.4001 0.567956C1.07577 0.289953 0.574707 0.520408 0.574707 0.947584L0.574707 8.77336Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
          <div
            v-if="
              (midiaSelectedModal.mime === 'video/mp4' ||
              midiaSelectedModal.mime === 'video/x-matroska' ||
              midiaSelectedModal.mime === 'video/quicktime') &&
              midiaSelectedModal.disk === 'panda'
            "
          >
          <button
              class="btn-delete col-md-12 col-12 mb-2"
              @click.prevent="removeVideo(midiaSelectedModal.id)"
              v-if="midiaSelectedModal.id"
            >
              Deletar vídeo
              <svg
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.574707 8.77336C0.574707 9.20054 1.07577 9.43099 1.4001 9.15299L5.96514 5.2401C6.19795 5.04055 6.19795 4.68039 5.96514 4.48085L1.4001 0.567956C1.07577 0.289953 0.574707 0.520408 0.574707 0.947584L0.574707 8.77336Z"
                  fill="white"
                />
              </svg>
            </button>
            <button
              class="btn-upload col-md-12 col-12"
              @click.prevent="addUrlPanda"
            >
              Selecionar vídeo
              <svg
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.574707 8.77336C0.574707 9.20054 1.07577 9.43099 1.4001 9.15299L5.96514 5.2401C6.19795 5.04055 6.19795 4.68039 5.96514 4.48085L1.4001 0.567956C1.07577 0.289953 0.574707 0.520408 0.574707 0.947584L0.574707 8.77336Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
          <div v-if="midiaSelectedModal.mime === 'vimeo'">
            <button
              class="btn-upload col-md-12 col-12"
              @click.prevent="addUrlVimeo"
            >
              Selecionar vídeo
              <svg
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.574707 8.77336C0.574707 9.20054 1.07577 9.43099 1.4001 9.15299L5.96514 5.2401C6.19795 5.04055 6.19795 4.68039 5.96514 4.48085L1.4001 0.567956C1.07577 0.289953 0.574707 0.520408 0.574707 0.947584L0.574707 8.77336Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <b-col cols="12" md="10" class="mx-auto pt-1">
        <div>
          <div class="flexHeader">
            <div>
              <div class="title">Gestão de Mídias</div>
              <div class="desc mt-1">
                Suba um arquivo ou selecione uma mídia disponível
              </div>
              <div class="mt-2 w100" v-if="enviarpanda === true && notLinks !== 'integrations'">
                <b-progress class="plans" :max="100">
                  <b-progress-bar :value="value"></b-progress-bar>
                </b-progress>
                <div class="desc mt-1">{{ value2 }} / {{ value1 }}</div>
              </div>
            </div>
            <div>
              <!-- {{ typeLesson }} -->
              <b-form-select
                @change="getMidiasNotConnection()"
                v-model="mediaTypeSelect"
                class="mb-3"
                v-if="media"
              >
                <b-form-select-option value="image"
                  >Imagens</b-form-select-option
                >
                <b-form-select-option value="video"
                  >Vídeos</b-form-select-option
                >
                <b-form-select-option value="application"
                  >Documentos</b-form-select-option
                >
                <b-form-select-option
                  v-if="typeLesson !== 'VIDEO' || allItens !== false"
                  value="audio"
                  >Audio</b-form-select-option
                >
                <b-form-select-option value="todo">Todos</b-form-select-option>
              </b-form-select>
            </div>
          </div>
          <div class="lineDiv mt-3 mb-3 col-md-12 col-12"></div>
          <b-row class="mt-1 mb-3">
            <b-col cols="12" md="11" class="ml-3">
              <b-row class="d-flex xjustify2" v-if="notLinks === 'notlinks'">
                <div
                  class="tabs-upload"
                  :class="{ active: enviar }"
                  @click="tabs('enviar')"
                >
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.72667 1.3114V9.5375M12.4533 9.5375V12.2795C12.4533 12.6432 12.3193 12.9919 12.0806 13.249C11.8419 13.5061 11.5183 13.6506 11.1807 13.6506H2.27259C1.93508 13.6506 1.61139 13.5061 1.37273 13.249C1.13408 12.9919 1 12.6432 1 12.2795V9.5375H12.4533ZM9.90815 4.73894L6.72667 1.3114L9.90815 4.73894ZM6.72667 1.3114L3.54519 4.73894L6.72667 1.3114Z"
                      stroke="#333333"
                      stroke-width="1.7"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div class="text">Enviar arquivo</div>
                </div>
                <div
                  class="tabs-upload"
                  :class="{ active: media }"
                  @click="tabs('media')"
                >
                  <svg
                    width="16"
                    height="15"
                    viewBox="0 0 16 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.7367 4.73894V13.6506H2.28334V4.73894M6.73741 7.48098H9.2826M1.01074 1.3114H15.0093V4.73894H1.01074V1.3114Z"
                      stroke="#333333"
                      stroke-width="1.7"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div class="text">Biblioteca de mídias</div>
                </div>
              </b-row>
              <b-row class="d-flex xjustify" v-else-if="notLinks === 'links'">
                <div
                  class="tabs-upload"
                  :class="{ active: enviarpanda }"
                  @click="tabs('enviarpanda')"
                  v-if="hasPanda"
                >
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.72667 1.3114V9.5375M12.4533 9.5375V12.2795C12.4533 12.6432 12.3193 12.9919 12.0806 13.249C11.8419 13.5061 11.5183 13.6506 11.1807 13.6506H2.27259C1.93508 13.6506 1.61139 13.5061 1.37273 13.249C1.13408 12.9919 1 12.6432 1 12.2795V9.5375H12.4533ZM9.90815 4.73894L6.72667 1.3114L9.90815 4.73894ZM6.72667 1.3114L3.54519 4.73894L6.72667 1.3114Z"
                      stroke="#333333"
                      stroke-width="1.7"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div class="text">Enviar arquivo</div>
                </div>
                <div
                  class="tabs-upload"
                  :class="{ active: media }"
                  @click="tabs('media')"
                  v-if="hasPanda"
                >
                  <svg
                    width="16"
                    height="15"
                    viewBox="0 0 16 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.7367 4.73894V13.6506H2.28334V4.73894M6.73741 7.48098H9.2826M1.01074 1.3114H15.0093V4.73894H1.01074V1.3114Z"
                      stroke="#333333"
                      stroke-width="1.7"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div class="text">Biblioteca de mídias</div>
                </div>
                <div
                  class="tabs-upload"
                  v-if="typeLesson !== 'AUDIO'"
                  :class="{ active: youtube }"
                  @click="tabs('youtube')"
                >
                  <svg
                    class="yt"
                    width="17"
                    height="13"
                    viewBox="0 0 16 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.9963 3.87674C16.0323 2.8589 15.8048 1.84867 15.3349 0.93986C15.0161 0.566955 14.5737 0.3153 14.0848 0.228751C12.0624 0.0492177 10.0318 -0.0243678 8.00135 0.00830768C5.97838 -0.025854 3.95498 0.0453566 1.93974 0.22164C1.54131 0.292552 1.17259 0.4754 0.878587 0.747861C0.224454 1.33808 0.151772 2.34786 0.0790907 3.20119C-0.0263636 4.73542 -0.0263636 6.2749 0.0790907 7.80919C0.100117 8.28947 0.173206 8.7662 0.297135 9.2314C0.384767 9.59051 0.562074 9.92282 0.813173 10.1985C1.10918 10.4854 1.48648 10.6787 1.89613 10.7532C3.46307 10.9424 5.04193 11.0208 6.6204 10.9878C9.16425 11.0234 11.3956 10.9878 14.0339 10.7887C14.4536 10.7188 14.8415 10.5253 15.1459 10.2341C15.3494 10.0349 15.5014 9.79112 15.5893 9.52296C15.8493 8.74259 15.9769 7.92588 15.9672 7.10519C15.9963 6.70697 15.9963 4.30342 15.9963 3.87674ZM6.35877 7.53185V3.13008L10.6615 5.34164C9.45498 5.99586 7.86325 6.73541 6.35877 7.53185Z"
                      fill="#333333"
                    />
                  </svg>
                  <div class="text">YouTube</div>
                </div>
                <div
                  class="tabs-upload"
                  :class="{ active: vimeo }"
                  v-if="typeLesson !== 'AUDIO'"
                  @click="tabs('vimeo')"
                >
                  <svg
                    class="vimeo"
                    width="15"
                    height="15"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.66671 5.84666L6.33337 3.92C6.13077 3.80303 5.90097 3.74142 5.66704 3.74136C5.43312 3.7413 5.20329 3.80279 5.00063 3.91965C4.79798 4.03651 4.62965 4.20464 4.51252 4.40714C4.3954 4.60964 4.33361 4.83939 4.33337 5.07333V8.92666C4.33361 9.16046 4.39534 9.39013 4.51235 9.59253C4.62936 9.79499 4.79755 9.96306 5.00004 10.08C5.20273 10.197 5.43266 10.2586 5.66671 10.2586C5.90077 10.2586 6.13071 10.197 6.33337 10.08L9.66671 8.15333C9.86877 8.03613 10.0365 7.86786 10.153 7.66546C10.2696 7.46306 10.331 7.2336 10.331 7C10.331 6.7664 10.2696 6.53693 10.153 6.33453C10.0365 6.13213 9.86877 5.96386 9.66671 5.84666ZM9.00004 7L5.66671 8.92666V5.07333L9.00004 7ZM7.00004 0.333328C5.68151 0.333328 4.39257 0.724322 3.29624 1.45686C2.19991 2.18941 1.34543 3.2306 0.840848 4.44878C0.336261 5.66693 0.204241 7.0074 0.461475 8.3006C0.718708 9.5938 1.35365 10.7817 2.286 11.7141C3.21835 12.6464 4.40623 13.2813 5.69944 13.5386C6.99264 13.7958 8.33311 13.6638 9.55124 13.1592C10.7694 12.6546 11.8106 11.8001 12.5432 10.7038C13.2757 9.60746 13.6667 8.31853 13.6667 7C13.6667 6.12453 13.4942 5.25761 13.1592 4.44878C12.8242 3.63994 12.3332 2.90501 11.7141 2.28595C11.095 1.66689 10.3601 1.17583 9.55124 0.840795C8.74244 0.505768 7.87551 0.333328 7.00004 0.333328ZM7.00004 12.3333C5.94524 12.3333 4.91407 12.0205 4.037 11.4345C3.15994 10.8485 2.47635 10.0155 2.07269 9.04099C1.66902 8.06646 1.5634 6.99406 1.76919 5.95953C1.97497 4.92495 2.48293 3.97464 3.22881 3.22876C3.97469 2.48288 4.92499 1.97493 5.95957 1.76914C6.99411 1.56335 8.06651 1.66897 9.04104 2.07264C10.0156 2.47631 10.8485 3.15989 11.4346 4.03695C12.0206 4.91402 12.3334 5.94513 12.3334 7C12.3334 8.41446 11.7715 9.77106 10.7713 10.7713C9.77111 11.7714 8.41451 12.3333 7.00004 12.3333Z"
                      fill="#333333"
                    />
                  </svg>
                  <div class="text">Vimeo</div>
                </div>
                <div
                  class="tabs-upload"
                  :class="{ active: linkvideo }"
                  v-if="typeLesson !== 'AUDIO'"
                  @click="tabs('linkvideo')"
                >
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.3762 9.16335C6.66927 9.55515 7.04317 9.87934 7.47254 10.1139C7.90192 10.3485 8.37673 10.488 8.86476 10.523C9.35279 10.5579 9.84263 10.4875 10.3011 10.3165C10.7595 10.1455 11.1758 9.87789 11.5217 9.53186L13.5689 7.48459C14.1905 6.84105 14.5344 5.97914 14.5266 5.0845C14.5189 4.18985 14.16 3.33405 13.5274 2.70141C12.8947 2.06878 12.0389 1.70993 11.1443 1.70216C10.2496 1.69438 9.38774 2.0383 8.74421 2.65985L7.57044 3.82679M9.10589 7.7985C8.81282 7.4067 8.43892 7.08251 8.00954 6.84793C7.58017 6.61334 7.10536 6.47384 6.61733 6.43888C6.1293 6.40393 5.63946 6.47435 5.18104 6.64535C4.72261 6.81636 4.30633 7.08395 3.96042 7.42999L1.91314 9.47726C1.2916 10.1208 0.947677 10.9827 0.955451 11.8774C0.963225 12.772 1.32207 13.6278 1.95471 14.2604C2.58734 14.8931 3.44315 15.2519 4.33779 15.2597C5.23244 15.2675 6.09435 14.9235 6.73788 14.302L7.90483 13.1351"
                      stroke="#333333"
                      stroke-width="1.7"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div class="text">Link de Vídeo</div>
                </div>
                <div
                  class="tabs-upload"
                  @click="removeLogin()"
                  v-if="connectGoogleId !== null || connectVimeoId !== null"
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.625 8.75L2.5 5.625L5.625 2.5"
                      stroke="#B5B9C5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.5 12.5V8.125C12.5 7.46194 12.2366 6.82606 11.7677 6.35725C11.2989 5.88839 10.6631 5.625 10 5.625H2.5"
                      stroke="#B5B9C5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div class="text2">Voltar</div>
                </div>
              </b-row>
              <b-row class="d-flex xjustify" v-else-if="notLinks === 'integrations'">
                <div
                  class="tabs-upload"
                  v-if="typeLesson !== 'AUDIO'"
                  :class="{ active: youtube }"
                  @click="tabs('youtube')"
                >
                  <svg
                    class="yt"
                    width="17"
                    height="13"
                    viewBox="0 0 16 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.9963 3.87674C16.0323 2.8589 15.8048 1.84867 15.3349 0.93986C15.0161 0.566955 14.5737 0.3153 14.0848 0.228751C12.0624 0.0492177 10.0318 -0.0243678 8.00135 0.00830768C5.97838 -0.025854 3.95498 0.0453566 1.93974 0.22164C1.54131 0.292552 1.17259 0.4754 0.878587 0.747861C0.224454 1.33808 0.151772 2.34786 0.0790907 3.20119C-0.0263636 4.73542 -0.0263636 6.2749 0.0790907 7.80919C0.100117 8.28947 0.173206 8.7662 0.297135 9.2314C0.384767 9.59051 0.562074 9.92282 0.813173 10.1985C1.10918 10.4854 1.48648 10.6787 1.89613 10.7532C3.46307 10.9424 5.04193 11.0208 6.6204 10.9878C9.16425 11.0234 11.3956 10.9878 14.0339 10.7887C14.4536 10.7188 14.8415 10.5253 15.1459 10.2341C15.3494 10.0349 15.5014 9.79112 15.5893 9.52296C15.8493 8.74259 15.9769 7.92588 15.9672 7.10519C15.9963 6.70697 15.9963 4.30342 15.9963 3.87674ZM6.35877 7.53185V3.13008L10.6615 5.34164C9.45498 5.99586 7.86325 6.73541 6.35877 7.53185Z"
                      fill="#333333"
                    />
                  </svg>
                  <div class="text">YouTube</div>
                </div>
                <div
                  class="tabs-upload"
                  :class="{ active: vimeo }"
                  v-if="typeLesson !== 'AUDIO'"
                  @click="tabs('vimeo')"
                >
                  <svg
                    class="vimeo"
                    width="15"
                    height="15"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.66671 5.84666L6.33337 3.92C6.13077 3.80303 5.90097 3.74142 5.66704 3.74136C5.43312 3.7413 5.20329 3.80279 5.00063 3.91965C4.79798 4.03651 4.62965 4.20464 4.51252 4.40714C4.3954 4.60964 4.33361 4.83939 4.33337 5.07333V8.92666C4.33361 9.16046 4.39534 9.39013 4.51235 9.59253C4.62936 9.79499 4.79755 9.96306 5.00004 10.08C5.20273 10.197 5.43266 10.2586 5.66671 10.2586C5.90077 10.2586 6.13071 10.197 6.33337 10.08L9.66671 8.15333C9.86877 8.03613 10.0365 7.86786 10.153 7.66546C10.2696 7.46306 10.331 7.2336 10.331 7C10.331 6.7664 10.2696 6.53693 10.153 6.33453C10.0365 6.13213 9.86877 5.96386 9.66671 5.84666ZM9.00004 7L5.66671 8.92666V5.07333L9.00004 7ZM7.00004 0.333328C5.68151 0.333328 4.39257 0.724322 3.29624 1.45686C2.19991 2.18941 1.34543 3.2306 0.840848 4.44878C0.336261 5.66693 0.204241 7.0074 0.461475 8.3006C0.718708 9.5938 1.35365 10.7817 2.286 11.7141C3.21835 12.6464 4.40623 13.2813 5.69944 13.5386C6.99264 13.7958 8.33311 13.6638 9.55124 13.1592C10.7694 12.6546 11.8106 11.8001 12.5432 10.7038C13.2757 9.60746 13.6667 8.31853 13.6667 7C13.6667 6.12453 13.4942 5.25761 13.1592 4.44878C12.8242 3.63994 12.3332 2.90501 11.7141 2.28595C11.095 1.66689 10.3601 1.17583 9.55124 0.840795C8.74244 0.505768 7.87551 0.333328 7.00004 0.333328ZM7.00004 12.3333C5.94524 12.3333 4.91407 12.0205 4.037 11.4345C3.15994 10.8485 2.47635 10.0155 2.07269 9.04099C1.66902 8.06646 1.5634 6.99406 1.76919 5.95953C1.97497 4.92495 2.48293 3.97464 3.22881 3.22876C3.97469 2.48288 4.92499 1.97493 5.95957 1.76914C6.99411 1.56335 8.06651 1.66897 9.04104 2.07264C10.0156 2.47631 10.8485 3.15989 11.4346 4.03695C12.0206 4.91402 12.3334 5.94513 12.3334 7C12.3334 8.41446 11.7715 9.77106 10.7713 10.7713C9.77111 11.7714 8.41451 12.3333 7.00004 12.3333Z"
                      fill="#333333"
                    />
                  </svg>
                  <div class="text">Vimeo</div>
                </div>
                <div
                  class="tabs-upload"
                  @click="removeLogin()"
                  v-if="connectGoogleId !== null || connectVimeoId !== null"
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.625 8.75L2.5 5.625L5.625 2.5"
                      stroke="#B5B9C5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.5 12.5V8.125C12.5 7.46194 12.2366 6.82606 11.7677 6.35725C11.2989 5.88839 10.6631 5.625 10 5.625H2.5"
                      stroke="#B5B9C5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div class="text2">Voltar</div>
                </div>
              </b-row>

              <b-row class="d-flex xjustify" v-else>
                <div
                  class="tabs-upload"
                  :class="{ active: enviarpanda }"
                  @click="tabs('enviarpanda')"
                  v-if="hasPanda"
                >
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.72667 1.3114V9.5375M12.4533 9.5375V12.2795C12.4533 12.6432 12.3193 12.9919 12.0806 13.249C11.8419 13.5061 11.5183 13.6506 11.1807 13.6506H2.27259C1.93508 13.6506 1.61139 13.5061 1.37273 13.249C1.13408 12.9919 1 12.6432 1 12.2795V9.5375H12.4533ZM9.90815 4.73894L6.72667 1.3114L9.90815 4.73894ZM6.72667 1.3114L3.54519 4.73894L6.72667 1.3114Z"
                      stroke="#333333"
                      stroke-width="1.7"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div class="text">Enviar arquivo</div>
                </div>
                <div
                  class="tabs-upload"
                  :class="{ active: media }"
                  @click="tabs('media')"
                  v-if="hasPanda"
                >
                  <svg
                    width="16"
                    height="15"
                    viewBox="0 0 16 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.7367 4.73894V13.6506H2.28334V4.73894M6.73741 7.48098H9.2826M1.01074 1.3114H15.0093V4.73894H1.01074V1.3114Z"
                      stroke="#333333"
                      stroke-width="1.7"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div class="text">Biblioteca de mídias</div>
                </div>
                <div
                  class="tabs-upload"
                  :class="{ active: youtube }"
                  v-if="typeLesson !== 'AUDIO'"
                  @click="tabs('youtube')"
                >
                  <svg
                    class="yt"
                    width="17"
                    height="13"
                    viewBox="0 0 16 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.9963 3.87674C16.0323 2.8589 15.8048 1.84867 15.3349 0.93986C15.0161 0.566955 14.5737 0.3153 14.0848 0.228751C12.0624 0.0492177 10.0318 -0.0243678 8.00135 0.00830768C5.97838 -0.025854 3.95498 0.0453566 1.93974 0.22164C1.54131 0.292552 1.17259 0.4754 0.878587 0.747861C0.224454 1.33808 0.151772 2.34786 0.0790907 3.20119C-0.0263636 4.73542 -0.0263636 6.2749 0.0790907 7.80919C0.100117 8.28947 0.173206 8.7662 0.297135 9.2314C0.384767 9.59051 0.562074 9.92282 0.813173 10.1985C1.10918 10.4854 1.48648 10.6787 1.89613 10.7532C3.46307 10.9424 5.04193 11.0208 6.6204 10.9878C9.16425 11.0234 11.3956 10.9878 14.0339 10.7887C14.4536 10.7188 14.8415 10.5253 15.1459 10.2341C15.3494 10.0349 15.5014 9.79112 15.5893 9.52296C15.8493 8.74259 15.9769 7.92588 15.9672 7.10519C15.9963 6.70697 15.9963 4.30342 15.9963 3.87674ZM6.35877 7.53185V3.13008L10.6615 5.34164C9.45498 5.99586 7.86325 6.73541 6.35877 7.53185Z"
                      fill="#333333"
                    />
                  </svg>
                  <div class="text">YouTube</div>
                </div>
                <div
                  class="tabs-upload"
                  v-if="typeLesson !== 'AUDIO'"
                  :class="{ active: vimeo }"
                  @click="tabs('vimeo')"
                >
                  <svg
                    class="vimeo"
                    width="15"
                    height="15"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.66671 5.84666L6.33337 3.92C6.13077 3.80303 5.90097 3.74142 5.66704 3.74136C5.43312 3.7413 5.20329 3.80279 5.00063 3.91965C4.79798 4.03651 4.62965 4.20464 4.51252 4.40714C4.3954 4.60964 4.33361 4.83939 4.33337 5.07333V8.92666C4.33361 9.16046 4.39534 9.39013 4.51235 9.59253C4.62936 9.79499 4.79755 9.96306 5.00004 10.08C5.20273 10.197 5.43266 10.2586 5.66671 10.2586C5.90077 10.2586 6.13071 10.197 6.33337 10.08L9.66671 8.15333C9.86877 8.03613 10.0365 7.86786 10.153 7.66546C10.2696 7.46306 10.331 7.2336 10.331 7C10.331 6.7664 10.2696 6.53693 10.153 6.33453C10.0365 6.13213 9.86877 5.96386 9.66671 5.84666ZM9.00004 7L5.66671 8.92666V5.07333L9.00004 7ZM7.00004 0.333328C5.68151 0.333328 4.39257 0.724322 3.29624 1.45686C2.19991 2.18941 1.34543 3.2306 0.840848 4.44878C0.336261 5.66693 0.204241 7.0074 0.461475 8.3006C0.718708 9.5938 1.35365 10.7817 2.286 11.7141C3.21835 12.6464 4.40623 13.2813 5.69944 13.5386C6.99264 13.7958 8.33311 13.6638 9.55124 13.1592C10.7694 12.6546 11.8106 11.8001 12.5432 10.7038C13.2757 9.60746 13.6667 8.31853 13.6667 7C13.6667 6.12453 13.4942 5.25761 13.1592 4.44878C12.8242 3.63994 12.3332 2.90501 11.7141 2.28595C11.095 1.66689 10.3601 1.17583 9.55124 0.840795C8.74244 0.505768 7.87551 0.333328 7.00004 0.333328ZM7.00004 12.3333C5.94524 12.3333 4.91407 12.0205 4.037 11.4345C3.15994 10.8485 2.47635 10.0155 2.07269 9.04099C1.66902 8.06646 1.5634 6.99406 1.76919 5.95953C1.97497 4.92495 2.48293 3.97464 3.22881 3.22876C3.97469 2.48288 4.92499 1.97493 5.95957 1.76914C6.99411 1.56335 8.06651 1.66897 9.04104 2.07264C10.0156 2.47631 10.8485 3.15989 11.4346 4.03695C12.0206 4.91402 12.3334 5.94513 12.3334 7C12.3334 8.41446 11.7715 9.77106 10.7713 10.7713C9.77111 11.7714 8.41451 12.3333 7.00004 12.3333Z"
                      fill="#333333"
                    />
                  </svg>
                  <div class="text">Vimeo</div>
                </div>
                <!-- <div class="tabs-upload" :class="{ active:linkVideo }" @click="tabs('linkVideo')">
                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.3762 9.16335C6.66927 9.55515 7.04317 9.87934 7.47254 10.1139C7.90192 10.3485 8.37673 10.488 8.86476 10.523C9.35279 10.5579 9.84263 10.4875 10.3011 10.3165C10.7595 10.1455 11.1758 9.87789 11.5217 9.53186L13.5689 7.48459C14.1905 6.84105 14.5344 5.97914 14.5266 5.0845C14.5189 4.18985 14.16 3.33405 13.5274 2.70141C12.8947 2.06878 12.0389 1.70993 11.1443 1.70216C10.2496 1.69438 9.38774 2.0383 8.74421 2.65985L7.57044 3.82679M9.10589 7.7985C8.81282 7.4067 8.43892 7.08251 8.00954 6.84793C7.58017 6.61334 7.10536 6.47384 6.61733 6.43888C6.1293 6.40393 5.63946 6.47435 5.18104 6.64535C4.72261 6.81636 4.30633 7.08395 3.96042 7.42999L1.91314 9.47726C1.2916 10.1208 0.947677 10.9827 0.955451 11.8774C0.963225 12.772 1.32207 13.6278 1.95471 14.2604C2.58734 14.8931 3.44315 15.2519 4.33779 15.2597C5.23244 15.2675 6.09435 14.9235 6.73788 14.302L7.90483 13.1351" stroke="#333333" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <div class="text">
                                        Link de Vídeo
                                    </div>
                                </div> -->
                <div
                  class="tabs-upload"
                  @click="removeLogin()"
                  v-if="connectGoogleId !== null || connectVimeoId !== null"
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.625 8.75L2.5 5.625L5.625 2.5"
                      stroke="#B5B9C5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.5 12.5V8.125C12.5 7.46194 12.2366 6.82606 11.7677 6.35725C11.2989 5.88839 10.6631 5.625 10 5.625H2.5"
                      stroke="#B5B9C5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div class="text">Voltar</div>
                </div>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-1 mb-1" v-if="enviarpanda === true">
            <b-col cols="12" md="12" class="testezindex">
              <b-form-group label="" label-for="global_affiliation">
                <b-form-group label="" label-for="contract" data-anima="top">
                  <b-form-file
                    id="arquivo"
                    name="arquivo"
                    class="arquivo-input"
                    :class="{ hideBox: !vifteste() }"
                    plain
                    :accept="typeUpload"
                    multiple
                    @change="changeFiles"
                    v-validate="'required'"
                  ></b-form-file>
                  <!-- accept=".pdf,.zip,.rar,.7zip" -->
                  <div v-if="vifteste()" class="drag-drop">
                    <div class="text-center p-5">
                      <h4 class="newText">
                        Solte seus arquivos aqui<br />
                        <div class="newTextDiv">ou</div>
                      </h4>
                      <label class="btn btn-lg newBtn"
                        >Selecionar Arquivos</label
                      >
                    </div>
                  </div>

                  <svg
                    @click="clearList()"
                    v-if="!vifteste()"
                    style="margin: 5px; cursor: pointer"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.625 8.75L2.5 5.625L5.625 2.5"
                      stroke="#B5B9C5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.5 12.5V8.125C12.5 7.46194 12.2366 6.82606 11.7677 6.35725C11.2989 5.88839 10.6631 5.625 10 5.625H2.5"
                      stroke="#B5B9C5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div class="mt-3 displayImages">
                    <li
                      v-for="(file, index) in files"
                      :key="file.name"
                      class="arquivo-item"
                      data-anima="top"
                      @click="selectMidiasUploaded(file.data_file)"
                    >
                      <div class="thumb">
                        <img
                          class="td-image-thumb"
                          src="@/assets/img/semimagem.jpg"
                        />
                      </div>
                      <div class="text-center mt-2" style="word-break: break-all;">
                        <span v-if="typeLesson === 'AUDIO'">{{
                          getNameAudio(index)
                        }}</span>
                        <span v-else>{{ file.name }}</span>
                      </div>
                      <div class="text-center mt-2" style="word-break: break-all;">
                        <span>{{ file.textstatus }}</span>
                      </div>
                      <div class="d-flex text-center justify-content-md-center">
                        <div class="item-lixeira">
                          <div
                            class="justify-content-center align-items-center"
                          >
                            <b-spinner
                              v-if="
                                file.textstatus !==
                                  'Upload feito com Sucesso' &&
                                file.textstatus !==
                                  'Erro ao fazer o Pré-Upload' &&
                                file.textstatus !== 'Fazendo Upload' &&
                                file.textstatus !== 'Erro ao fazer Upload'
                              "
                              variant="dark"
                              label="Spinning"
                            ></b-spinner>
                            <svg
                              v-if="
                                file.textstatus ===
                                  'Erro ao fazer o Pré-Upload' ||
                                file.textstatus === 'Erro ao fazer Upload'
                              "
                              width="15"
                              height="16"
                              viewBox="0 0 15 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.7864 1.62085L0.973389 14.4338M0.973389 1.62085L13.7864 14.4338"
                                stroke="#FF0C37"
                                stroke-width="1.7"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <svg
                              v-if="
                                file.textstatus === 'Upload feito com Sucesso'
                              "
                              width="13"
                              height="10"
                              viewBox="0 0 13 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.7022 0.289387C12.6162 0.197689 12.5139 0.124912 12.4012 0.0752425C12.2885 0.0255733 12.1676 0 12.0455 0C11.9234 0 11.8025 0.0255733 11.6897 0.0752425C11.577 0.124912 11.4747 0.197689 11.3887 0.289387L4.49736 7.58766L1.60206 4.51574C1.51277 4.42447 1.40738 4.35275 1.29188 4.30462C1.17639 4.25649 1.05306 4.23291 0.92894 4.23516C0.804822 4.23741 0.68234 4.26559 0.568489 4.31793C0.454648 4.37017 0.351656 4.44579 0.265408 4.5402C0.179159 4.63461 0.111347 4.74604 0.0658268 4.86823C0.020316 4.99042 -0.00200475 5.12083 0.000141283 5.25212C0.00228732 5.38332 0.0288629 5.51294 0.078342 5.63328C0.12783 5.75371 0.199251 5.86259 0.288533 5.95387L3.84059 9.71061C3.92658 9.80228 4.0289 9.87507 4.14161 9.92477C4.25434 9.97437 4.37524 10 4.49736 10C4.61947 10 4.74035 9.97437 4.85311 9.92477C4.96577 9.87507 5.06808 9.80228 5.15411 9.71061L12.7022 1.72752C12.7961 1.63591 12.8711 1.52472 12.9223 1.40096C12.9735 1.27721 13 1.14356 13 1.00845C13 0.873345 12.9735 0.739707 12.9223 0.615949C12.8711 0.492192 12.7961 0.381006 12.7022 0.289387Z"
                                fill="#00E4A0"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <b-progress
                        v-if="
                          file.textstatus !== 'Fazendo Pré-Upload' ||
                          file.textstatus !== 'Erro ao fazer Upload'
                        "
                        :value="file.porcentage"
                        show-progress
                        :max="100"
                        class="mt-3"
                      ></b-progress>
                      <div v-if="file.showRetryButton === true">
                        <button class="btn-reenviar" @click="retryUpload(index)">
                          <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          width="16" 
                          height="16" 
                          fill="currentColor" 
                          class="bi bi-arrow-counterclockwise" 
                          viewBox="0 0 16 16"> 
                            <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/> 
                            <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/> 
                          </svg>
                          Tentar Novamente</button>
                      </div>
                    </li>
                  </div>
                </b-form-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-1 mb-1" v-if="enviar === true">
            <UploadComponent
              sizex="80mb"
              :postAction="returnApi() + '/media'"
            />
          </b-row>
          <b-row class="mt-1 mb-1 spaceImageslib" v-if="media === true">
            <span class="tosearch">
              <div class="inputSearch">
                <img
                  src="@/assets/icons/search.svg"
                  @click="getMidiasNotConnection('search')"
                  class="Pointer search"
                />
                <b-form-input
                  class="input-busca"
                  @change="getMidiasNotConnection('search')"
                  v-model="searchMidias"
                  placeholder="Pesquise sua mídia"
                ></b-form-input>
              </div>
            </span>
            <b-col cols="12" md="12" class="gridListenImages">
              <div
                v-for="media in AllMedias.data"
                :key="media.id"
                @click="selectMidiasUploaded(media)"
                :id="'media' + media.id"
                :data-url="media.cdn_url"
                :data-id="media.id"
                class="allmedia box"
              >
                <div class="positionx hide">
                  <svg
                    width="28"
                    height="20"
                    viewBox="0 0 28 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M27.3587 0.578774C27.1734 0.395378 26.953 0.249823 26.7104 0.150485C26.4675 0.0511465 26.2071 0 25.9441 0C25.6811 0 25.4207 0.0511465 25.1778 0.150485C24.9352 0.249823 24.7148 0.395378 24.5295 0.578774L9.68661 15.1753L3.45058 9.03148C3.25828 8.84893 3.03127 8.70551 2.78251 8.60924C2.53377 8.51298 2.26813 8.46582 2.00079 8.47032C1.73346 8.47482 1.46966 8.53117 1.22444 8.63585C0.979241 8.74034 0.757414 8.89159 0.571648 9.0804C0.385882 9.26922 0.239824 9.49208 0.141781 9.73646C0.0437575 9.98085 -0.00431793 10.2417 0.000304302 10.5042C0.00492654 10.7666 0.0621663 11.0259 0.168737 11.2666C0.275327 11.5074 0.429156 11.7252 0.621457 11.9077L8.27205 19.4212C8.45726 19.6046 8.67763 19.7501 8.9204 19.8495C9.16318 19.9487 9.4236 20 9.68661 20C9.94962 20 10.21 19.9487 10.4528 19.8495C10.6955 19.7501 10.9159 19.6046 11.1012 19.4212L27.3587 3.45503C27.5609 3.27181 27.7223 3.04944 27.8326 2.80193C27.943 2.55441 28 2.28711 28 2.0169C28 1.74669 27.943 1.47941 27.8326 1.2319C27.7223 0.984384 27.5609 0.762013 27.3587 0.578774Z"
                      fill="#00E4A0"
                    />
                  </svg>
                </div>
                <iframe
                  class="imagem"
                  height="100%"
                  width="100%"
                  style="pointer-events: none; border: 0"
                  v-if="
                    media.mime === 'video/mp4' ||
                    media.mime === 'video/quicktime' ||
                    media.mime === 'video/x-matroska'
                  "
                  :src="media.cdn_url + '&controls=play-larg'"
                >
                </iframe>
                <img
                  loading="lazy"
                  v-if="
                    media.mime === 'image/png' ||
                    media.mime === 'image/jpeg' ||
                    media.mime === 'image/webp'
                  "
                  class="imagem"
                  :src="media.cdn_url"
                  :alt="media.title"
                />
                <img
                  loading="lazy"
                  class="imgMidia"
                  v-else-if="media.mime === 'application/zip'"
                  src="https://app.greenn.club/zip.png"
                  :alt="media.title"
                />
                <img
                  loading="lazy"
                  class="imgMidia"
                  v-else-if="
                    media.mime === 'application/msword' ||
                    media.mime ===
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  "
                  src="https://app.greenn.club/doc.png"
                  :alt="media.title"
                />
                <img
                  loading="lazy"
                  class="imgMidia"
                  v-else-if="
                    media.mime === 'application/vnd.ms-excel' ||
                    media.mime ===
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  "
                  src="https://app.greenn.club/xls.png"
                  :alt="media.title"
                />
                <img
                  loading="lazy"
                  class="imgMidiaAudio 4"
                  v-else-if="media.mime === 'audio/mpeg'"
                  src="@/assets/img/audio.png"
                  :alt="media.title"
                />
                <div
                  :class="'spacePdfLock ' + media.block_pdf"
                  v-if="
                    media.mime === 'application/pdf' ||
                    media.mime === 'ppt' ||
                    media.mime === 'pptx' ||
                    media.mime === 'application/msword' ||
                    media.mime === 'application/vnd.ms-excel'
                  ">
                  <iframe
                    v-if="
                      media.mime === 'application/pdf' ||
                      media.mime === 'ppt' ||
                      media.mime === 'pptx' ||
                      media.mime === 'application/msword'"
                    class="imagem"
                    height="100%"
                    width="100%"
                    :src="media.cdn_url">
                  </iframe>
                  <iframe
                    v-if="media.mime === 'application/vnd.ms-excel'"
                    class="imagem"
                    height="100%"
                    width="100%"
                  ></iframe>
                </div>
                <div class="titleVideo">{{ media.title }}</div>
              </div>
            </b-col>
            <div class="paginacao">
              <div class="txtPaginacao" @click="selectPageMedia('prev')">
                Anterior
              </div>
              <div class="txtPaginacao" @click="selectPageMedia('next')">
                Proxima
              </div>
            </div>
          </b-row>
          <b-row class="mt-1 mb-1 spaceImageslib" v-if="youtube === true">
            <b-col cols="12" md="12" class="d-flex image">
              <div class="listLinksVideos">
                <div v-if="connectGoogleId === null">
                  <b-list-group
                    v-for="google in GoogleConnections"
                    :key="google.id"
                  >
                    <b-list-group-item
                      class="listIntegrationYt"
                      @click="selectGoogleConnect(google.id)"
                      style="cursor: pointer"
                      >{{ google.id }} - {{ google.title }}</b-list-group-item
                    >
                  </b-list-group>
                  <b-form-group
                    label="Integração com o Google"
                    label-for="name-module"
                  >
                    <button
                      class="btnIntegraçãoVimeo"
                      @click="openWindowGoogle"
                    >
                      Fazer Integração
                    </button>
                    <!-- <btn-google></btn-google> -->
                  </b-form-group>
                  <iframe
                    v-if="openIntFrame"
                    height="0"
                    width="0"
                    :src="srcIframe"
                  ></iframe>
                </div>
                <div v-else>
                  <div class="inputSearch">
                    <img
                      src="@/assets/icons/search.svg"
                      @click="getVideoYt('search')"
                      class="Pointer search"
                    />
                    <b-form-input
                      class="input-busca"
                      @change="getVideoYt('search')"
                      v-model="searchVideo"
                      placeholder="Pesquise seu video"
                    ></b-form-input>
                  </div>
                  <div class="gridListen">
                    <b-list-group
                      v-for="googlemidias in MidiasGoogle"
                      :key="googlemidias.id.videoId"
                    >
                      <b-list-group-item
                        @click="selectUrlYoutube(googlemidias)"
                        class="listYt"
                      >
                        <div>
                          <img
                            v-if="googlemidias.snippet"
                            loading="lazy"
                            class="imgVideo"
                            :src="googlemidias.snippet.thumbnails.default.url"
                            :alt="googlemidias.snippet.thumbnails.default.url"
                          />
                        </div>
                        <div v-if="googlemidias.snippet" class="titleVideo">
                          {{ googlemidias.snippet.title }}
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                  <div class="paginacao">
                    <div class="txtPaginacao" @click="selectPageYt('prev')">
                      Anterior
                    </div>
                    <div class="txtPaginacao" @click="selectPageYt('next')">
                      Proxima
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-1 mb-1 spaceImageslib" v-if="vimeo === true">
            <b-col cols="12" md="12" class="d-flex image">
              <div class="listLinksVideos">
                <div v-if="connectVimeoId === null">
                  <b-list-group
                    v-for="vimeo in VimeoConnections"
                    :key="vimeo.id"
                  >
                    <b-list-group-item
                      class="listIntegration"
                      @click="selectVimeoConnect(vimeo.id)"
                      style="cursor: pointer"
                      >{{ vimeo.id }} - {{ vimeo.title }}</b-list-group-item
                    >
                  </b-list-group>
                  <b-form-group
                    label="Integração com o Vimeo"
                    label-for="name-module"
                  >
                    <button
                      class="btnIntegraçãoVimeo"
                      @click="openLinkIntegrationVimeo"
                    >
                      Fazer Integração
                    </button>
                  </b-form-group>
                </div>
                <div v-else>
                  <div class="inputSearch">
                    <img
                      src="@/assets/icons/search.svg"
                      @click="getVideoVimeo"
                      class="Pointer search"
                    />
                    <b-form-input
                      class="input-busca"
                      @change="getVideoVimeo"
                      v-model="searchVideo"
                      placeholder="Pesquise seu video"
                    ></b-form-input>
                  </div>
                  <div class="gridListen">
                    <b-list-group
                      v-for="vimeomidias in MidiasVimeo"
                      :key="vimeomidias.id"
                    >
                      <b-list-group-item
                        @click="selectUrlVimeo(vimeomidias)"
                        class="listYt"
                      >
                        <div>
                          <img
                            loading="lazy"
                            class="imgVideo"
                            :src="vimeomidias.pictures.sizes[2].link"
                            :alt="vimeomidias.name"
                          />
                        </div>
                        <div class="titleVideo">{{ vimeomidias.name }}</div>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                  <div class="paginacao">
                    <div class="txtPaginacao" @click="selectPageVimeo('prev')">
                      Anterior
                    </div>
                    <div class="txtPaginacao" @click="selectPageVimeo('next')">
                      Proxima
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-1 mb-1 spaceImageslib" v-if="linkvideo === true">
            <b-col cols="12" md="12" class="">
              <div class="listLinksVideos">
                <b-form-group label="Tipo de Link" label-for="name-module">
                  <b-form-select
                    @change="changeSelectedLink"
                    v-model="selectedLink"
                    :options="optionsType"
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  v-if="selectedLink === 'youtube'"
                  label="Youtube"
                  label-for="name-module"
                >
                  <b-form-input
                    v-model="midiaEmbedYt"
                    placeholder="Seu link"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  v-if="selectedLink === 'vimeo'"
                  label="Vimeo"
                  label-for="name-module"
                >
                  <b-form-input
                    v-model="midiaEmbedVimeo"
                    placeholder="Seu link"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  v-if="selectedLink === 'iframe'"
                  label="Iframe"
                  label-for="name-module"
                >
                  <b-form-textarea
                    id="textarea"
                    v-model="midiaEmbedIframe"
                    placeholder="Seu Iframe aqui..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
                <button
                  class="btn-upload col-md-4 col-4 mt-5"
                  @click="salvarMidiaLink"
                >
                  Selecionar vídeo
                  <svg
                    width="7"
                    height="10"
                    viewBox="0 0 7 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.574707 8.77336C0.574707 9.20054 1.07577 9.43099 1.4001 9.15299L5.96514 5.2401C6.19795 5.04055 6.19795 4.68039 5.96514 4.48085L1.4001 0.567956C1.07577 0.289953 0.574707 0.520408 0.574707 0.947584L0.574707 8.77336Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
              <!-- <div class="listLinksVideos">
                                <b-form-group label="Embed" label-for="name-module">
                                    <b-form-textarea
                                        id="textarea"
                                        class="textareaEmbed"
                                        placeholder="Cole seu Código Aqui."
                                        v-model="midiaEmbed"
                                    ></b-form-textarea>
                                </b-form-group>
                            </div> -->
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </b-modal>
  <ModalUploadPanda />
  <ModalPlanos :percentage="value" />
  </span>
</template>
<script>
import UploadComponent from "@/components/UploadComponent";
import BtnGoogle from "@/components/BtnGoogle";
import MediasService from "@/services/resources/MediaService";
import PandaService from "@/services/resources/PandaService";
import ConnectionService from "@/services/resources/ConnectionsService";
import SiteConfigSerivice from "@/services/resources/SiteConfigService";
import "video.js/dist/video-js.css";
import videojs from "video.js";
import "@devmobiliza/videojs-vimeo/dist/videojs-vimeo.esm";
import "videojs-youtube";
const servicePanda = PandaService.build();
const serviceSiteConfig = SiteConfigSerivice.build();
const serviceMedias = MediasService.build();
const serviceConnection = ConnectionService.build();
import Cookies from "js-cookie";
import notify from "@/services/libs/notificacao";
import axios from "axios";
import CustomFieldsService from "@/services/resources/CustomFieldsService";
const service = CustomFieldsService.build();
import Multiselect from "vue-multiselect";
import PlansService from "@/services/resources/PlansService";
const servicePlans = PlansService.build();
import ModalPlanos from "@/components/ModalPlanos.vue";
import { timingSafeEqual } from "crypto";

export default {
  components: {
    UploadComponent,
    BtnGoogle,
    Multiselect,
    ModalPlanos,
  },
  props: ["typeLesson"],
  data() {
    return {
      value: 0,
      value1: 0,
      value2: 0,
      videoFile: "",
      showRetryButton: false,
      externalId: "",
      statusVideoPanda: "",
      statusLogBack: "",
      intervalLogStatus: "",
      statusMap: {},
      uploadPercentage: 0,
      countStatus: 0,
      files: [],
      typeToUrl: "",
      enviar: true,
      enviarpanda: false,
      media: false,
      vimeo: false,
      youtube: false,
      embed: false,
      linkvideo: false,
      AllMedias: [],
      idMidia: "",
      idMidiaUrl: "",
      midiaYoutube: "",
      midiaVimeo: "",
      midiaEmbed: "",
      notLinks: false,
      clientId:
        "753585437519-c6kn7emvogui3emd98ji8811m3eue21l.apps.googleusercontent.com",
      GoogleConnections: "",
      connectGoogleId: null,
      VimeoConnections: "",
      connectVimeoId: null,
      vimeoUrlConnection: "",
      MidiasGoogle: "",
      MidiasVimeo: "",
      searchVideo: "",
      nextPageToken: "",
      prevPageToken: "",
      toPageToken: "",
      pageVimeo: 1,
      lastPageVimeo: 1,
      lastPageMedia: 1,
      pageMedia: 1,
      openModalMidia: false,
      midiaSelectedModal: null,
      searchMidias: "",
      openIntFrame: false,
      srcIframe: "",
      idMidiaType: "",
      midiaEmbedYt: "",
      midiaEmbedVimeo: "",
      midiaEmbedIframe: "",
      selectedLink: "vimeo",
      optionsType: [
        { value: "vimeo", text: "Link do Tipo Vimeo" },
        { value: "youtube", text: "Link do Tipo Youtube" },
        { value: "iframe", text: "Panda Iframe" },
      ],
      customInputs: null,
      optionsLessonCategory: [],
      mediaTypeSelect: "image",
      data: "",
      uploadPercentage: 0,
      hasPanda: true,
      typeUpload: ".mp3",
      idMidiaTitle: "",
      midiaSelectedModal2: null,
      selectInUpload: false,
      allItens: false,
      pandaQueueIsRunning: false,
      queueFiles: [],
    };
  },
  computed: {
    getNameAudio: function () {
      return (index) => {
        if (this.files[index].data_file) {
          return this.files[index].data_file.title;
        } else {
          return this.files[index].title;
        }
      };
    },
  },
  methods: {
    removeVideo(id){
      serviceMedias
      .destroy(id)
        .then((resp) => {
          //console.log("delete video", resp);
          notify('sucesso', 'Vídeo removido com Sucesso!');
          this.closeModalItem();
        })
        .catch((err) => {
          //console.log(err);
          var error = JSON.parse(err.response.data);;
          var msg = '';
          for (var indice in error) {
              msg += error[indice][0] + "<br>";
          }
          if (msg !== ''){
              notify('erro', msg);
          }
        });
    },
    bytesToSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    getPorcentage() {
      servicePlans
        .read("me")
        .then((resp) => {
          //console.log("get porcentage", resp);
          this.value = parseInt(resp.percentage, 10);
          this.value1 = resp.storage_limit;
          this.value2 = resp.used_storage;
          if(parseInt(resp.percentage, 10) >= 90 && !Cookies.get('modalPlans')){
            Cookies.set('modalPlans', true, { expires: 1 });
            this.$root.$emit("bv::show::modal", "modal-plans", "#btnShow");
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    saveTitle() {
      let data = {
        id: this.midiaSelectedModal.id,
        title: this.midiaSelectedModal.title,
      };
      serviceMedias
        .postID(data)
        .then((resp) => {
          this.idMidiaTitle = this.midiaSelectedModal.title;
          //console.log("update midia title", resp);
          notify("sucesso", "Titulo Alterado com Sucesso!");
        })
        .catch((err) => {
          //console.log(err);
          notify("erro", "Erro ao alterar Titulo");
        });
    },
    showModalUploadThumbAudio() {
      this.midiaSelectedModal2 = this.midiaSelectedModal;
      this.closeModalItem();
      this.$root.$emit("uploadcapaaudio");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    jsonToFormData(data) {
      const formData = new FormData();

      this.buildFormData(formData, data);
      return formData;
    },
    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;

        formData.append(parentKey, value);
      }
    },
    verifyResource() {
      let data = {
        id: "/has_panda",
      };
      serviceSiteConfig
        .read(data)
        .then((resp) => {
          //console.log("has panda", resp);
          if (resp.has_panda === "off") {
            this.typeUpload = ".mp3";
            this.mediaTypeSelect = "audio";
          } else {
            this.typeUpload = ".mp4, .mp3, .mkv";
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    clearList() {
      this.files = [];
    },
    vifteste() {
      if (this.files.length !== 0) {
        return false;
      } else {
        return true;
      }
    },
    async sendToBack() {
      var allfiles = this.files;
      this.files = allfiles.reverse();
      var video = this.files;
      window.temp1 = document.getElementById("arquivo");
      var externalId = "";
      var that = this;
      var url2 = this.returnApi();
      for (var i = 0; i < video.length; i++) {
        let xsize = video[i].size;
        let data = {
          mime: video[i].type,
          size: xsize.toString(),
          file_name: video[i].name,
          type: "video",
        };

        var header = {
          Authorization: Cookies.get("auth_greennCourse"),
          "Content-Type": "application/json",
        };

        if (video[i].type === "audio/mpeg") {
          data = {
            file: temp1.files[i],
          };
          header = {
            Authorization: Cookies.get("auth_greennCourse"),
            "Content-Type": "multipart/form-data",
          };
          data = this.jsonToFormData(data);
        }
        var indexx = i;
        var config = {
          method: "post",
          url: this.returnApi() + "/media",
          headers: header,
          data: data,
        };
        var status = "";
        await axios(config)
          .then(function (response) {
            status = response.data.status;
            externalId = response.data.external_id;
            //console.log('dentro do sendToBack', externalId);
            that.files[i].showRetryButton = false;
            that.getPorcentage();
            if (video[i].type === "audio/mpeg") {
              that.files[i].data_file = response.data;
              that.selectInUpload = true;
              that.$root.$emit("statusUpload", [i, "Upload feito com Sucesso"]);
              return;
            }
            that.$set(that.statusMap, externalId, 'UPLOADING');
            that.atualizarLog(externalId);
            that.$root.$emit("statusUpload", [indexx, "Fazendo Upload"]);
            that.sendFileToPandaQueue(response.data.location, indexx);
            if (indexx === (video.length - 1)) {
              that.initPandaQueue();
            }
          })
          .catch(function (err) {
            var error = err.response;
            console.log("Erro recebido:", error);
            that.files[i].showRetryButton = true;
            that.$root.$emit("statusUpload", [i, "Erro ao fazer o Pré-Upload"]);
            var error = err.response.data;
            var msg = '';
            for (var size in error) {
                msg += error[size][0] + "<br>";
            }
            if (msg !== ''){
                notify('erro', msg);
            }
            that.getPorcentage();
          });
        that.files[i].externalId = externalId;  
      }
    },
    initProgress(stop, index) {
      const timer = setInterval(() => {
        if (this.files[index].porcentage < 99) {
          const random = Math.random() * 20;
          if((this.files[index].porcentage + random) < 99){
            this.files[index].porcentage += random;
          }
          var teste = this.files;
          this.files = [];
          this.files = teste;
        } else {
          clearInterval(timer);
        }
      }, 1000);
    },
    async verificaStatusVideo(externalId, indexx) {
    
    const url = this.returnApi();
    const index = this.files.findIndex(file => file.external_id === externalId);
    
      axios.get(url + `/panda/${externalId}`).then(response => {

        let status = response.data.status;

        if (status === 'DRAFT' || !status || status === '') {
          this.$set(this.statusMap, externalId, 'UPLOADING');
          setTimeout(() => {
            this.verificaStatusVideo(externalId, indexx);
          }, 15000);
        }  
        if (status === 'CONVERTED' || status === 'CONVERTING') {
          this.$set(this.statusMap, externalId, 'UPLOADED');
          this.countStatus = 0;
          this.$root.$emit("statusUpload", [indexx, "Upload feito com Sucesso"]);
          this.files[indexx].porcentage = 100;
          this.goToNextItemPandaQueue(indexx);
        }
        }).catch(error => {
          this.countStatus++;
          if(this.countStatus == 7){
            if (this.status === 'NotFound') {
              this.$root.$emit("statusUpload", [indexx, "Erro ao fazer Upload"]);
              this.files[indexx].showRetryButton = true;
              this.goToNextItemPandaQueue(indexx);
            }
          }
          if(this.countStatus < 7){
            setTimeout(() => {
            this.verificaStatusVideo(externalId, indexx);
            }, 10000);
          }
        });
    },
    sendFileToPandaQueue(location, index) {
      //console.log('sendFileToPandaQueue');
      this.queueFiles[index] = {
        location,
        order: index
      }
    },
    initPandaQueue() {
      //console.log('initPandaQueue');
      const currentItem = this.queueFiles[0];
      this.pandaQueueIsRunning = true;
      this.sendPanda(currentItem.location, currentItem.order);
    },
    changeFiles(e) {
      //console.log('changeFiles');
      let videos = e.target.files;
      for (let i = 0; i < videos.length; i++) {
        videos[i].textstatus = "Fazendo Pré-Upload";
      }
      this.files = [];
      this.files = [...this.files, ...videos];
      this.files = this.files.reverse();
      this.videoFile = videos;
      setTimeout(() => {
        this.sendToBack();
      }, 500);
    },
    goToNextItemPandaQueue(previousIndex) {
      //console.log('goToNextItemPandaQueue');

      const nextIndex = previousIndex+1;
      const currentQueue = this.queueFiles[nextIndex];
      if (currentQueue) {
        this.sendPanda(currentQueue.location, currentQueue.order);
      } else {
        this.pandaQueueIsDone();
      }
    },
    pandaQueueIsDone() {
      //console.log('pandaQueueIsDone');

      this.$root.$emit("bv::show::modal", "modal-upload-planda", "#btnShow");
      this.pandaQueueIsRunning = false;
    },
    sendPanda(location, i) {
      const index = i;
      var that = this;
      var file = temp1.files[index];
      this.files[index].porcentage = 0;

      this.initProgress(true, index);

      axios
        .patch(location, file, {
          headers: {
            "Tus-Resumable": "1.0.0",
            "Content-Type": "application/offset+octet-stream",
            Accept: "text/plain",
            "Upload-Offset": "0",
          },
        })
        .then(async function () {
          //console.log('sendPanda', that.files[i].externalId);
          const externalIdA = that.files[index].externalId;
          setTimeout(() => {
              that.verificaStatusVideo(externalIdA, index);
            }, 10000);

          return;
        })
        .catch(async function (error) {
          console.error(error);
          if (error.response && error.response.status === 500) {
            //console.log('Erro 500 - tentando novamente em 10 segundos send panda');
            await new Promise(resolve => setTimeout(resolve, 10000));
            that.sendPanda(location, index);
          } else {
            that.files[index].showRetryButton = true;
            that.$root.$emit("statusUpload", [index, "Erro ao fazer Upload"]);
          }
        });
    },
    async retryUpload(index) {
      this.files[index].porcentage = 0;
      this.files[index].showRetryButton = false;
      this.$root.$emit("statusUpload", [index, "Refazendo Upload"]);
      await this.sendToBack(index);
    },
    atualizarLog(externalId) {
      const url = this.returnApi();

      const intervalLogStatus = setInterval(() => {
      const statusLogBack = this.statusMap[externalId];

      if (statusLogBack === 'UPLOADED') {
        axios.post(url + `/panda/log/${externalId}`, { statusLogBack })
          .then(response => {
            clearInterval(intervalLogStatus);
          })
          .catch(error => {
          });
      }
      if (statusLogBack === 'UPLOADING') {
        axios.post(url + `/panda/log/${externalId}`,  { statusLogBack })
          .then(response => {
          })
          .catch(error => {
          });
      }
      if (!statusLogBack) {
        axios.post(url + `/panda/log/${externalId}`,  { statusLogBack })
          .then(response => {
            // clearInterval(intervalLogStatus);
          })
          .catch(error => {
          });
        }
      }, 60000);      
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    updatePanda() {
      var that = this;
      // setTimeout(function () {
      //   that.getMidiasNotConnection(that.searchMidias);
      // }, 5000);
    },
    returnApi() {
      return process.env.VUE_APP_API_HOST;
    },
    customLabel({ text }) {
      return `${text}`;
    },
    getCustoms() {
      service
        .search()
        .then((resp) => {
          //console.log("get-fields", resp);
          resp.forEach((item) => {
            this.optionsLessonCategory.push({
              value: item.id,
              text: item.name,
            });
          });
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    updateCustoms(midia) {
      if (midia.use_custom_fields === 0 || midia.use_custom_fields === false) {
        var result = "off";
      } else {
        var result = "on";
      }

      let data = {
        id: midia.id + `/meta/use_custom_fields`,
        value: result,
      };

      serviceMedias
        .postID(data)
        .then((resp) => {
          //console.log("update midia protection", resp);
          if (resp.value === "off" || resp.value === false) {
            resp.value = false;
          } else {
            resp.value = true;
          }
          this.midiaSelectedModal.use_custom_fields = resp.value;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    changeSelectedLink(data) {
      if (data === "iframe" && this.typeToUrl === "uploadlesson") {
        // notify(
        //   "erro",
        //   "Ao selecionar o Metodo Panda desativa a opção de: Porcentagem para poder concluir uma aula"
        // );
      }
    },
    salvarMidiaLink() {
      if (
        this.midiaEmbedVimeo === "" &&
        this.midiaEmbedYt === "" &&
        this.midiaEmbedIframe === ""
      ) {
        notify("erro", "Coloque algum link");
        return;
      } else if (
        this.midiaEmbedVimeo === "" &&
        this.midiaEmbedYt === "" &&
        this.midiaEmbedIframe === ""
      ) {
        notify("erro", "Apenas um campo");
        return;
      }

      if (
        this.midiaEmbedYt !== "" &&
        this.midiaEmbedVimeo === "" &&
        this.midiaEmbedIframe === ""
      ) {
        var youtube_id = this.youtubeParser(this.midiaEmbedYt);
        let data = {
          id: {
            videoId: youtube_id,
          },
          snippet: {
            thumbnails: {
              standard: {
                url:
                  "https://img.youtube.com/vi/" + youtube_id + "/sddefault.jpg",
              },
            },
          },
        };
        let datato = this.typeToUrl;
        if (datato === "uploadintroducao") {
          this.$root.$emit("midiaintroducaoyt", data);
        } else if (datato === "uploadlesson") {
          this.$root.$emit("midialessonyt", data);
        } else if (datato === "videotermos") {
          this.$root.$emit("midiatermoyt", data);
        }
      } else if (
        this.midiaEmbedYt === "" &&
        this.midiaEmbedVimeo !== "" &&
        this.midiaEmbedIframe === ""
      ) {
        let data = {
          link: this.midiaEmbedVimeo,
          duration: "",
          pictures: {
            sizes: {
              0: { link: "vimeolink" },
            },
          },
        };
        let datato = this.typeToUrl;
        if (datato === "uploadintroducao") {
          this.$root.$emit("midiaintroducaovimeo", data);
        } else if (datato === "uploadlesson") {
          this.$root.$emit("midialessonvimeo", data);
        } else if (datato === "videotermos") {
          this.$root.$emit("midiatermovimeo", data);
        }
      } else if (
        this.midiaEmbedYt === "" &&
        this.midiaEmbedVimeo === "" &&
        this.midiaEmbedIframe !== ""
      ) {
        let data = {
          link: this.midiaEmbedIframe,
          duration: "",
          pictures: {
            sizes: {
              0: { link: "iframe" },
            },
          },
        };

        let datato = this.typeToUrl;
        if (datato === "uploadlesson") {
          this.$root.$emit("midialessoniframe", data);
        } else if (datato === "videotermos") {
          this.$root.$emit("midiatermoiframe", data);
        } else {
          notify("erro", "Iframe apenas para aulas no momento");
        }
      }

      this.closeModalItem();
      this.$root.$emit("bv::hide::modal", "modal-upload", "#btnShow");

      setTimeout(() => {
        (this.midiaEmbedYt = ""),
          (this.midiaEmbedVimeo = ""),
          (this.midiaEmbedIframe = "");
      }, 1000);
    },
    youtubeParser(url) {
      var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return match && match[7].length == 11 ? match[7] : false;
    },
    openWindowGoogle() {
      const assign =
        "auth_greennCourse" +
        "=" +
        escape(Cookies.get("auth_greennCourse")) +
        ";";
      const d = new Date();
      d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
      const expires = "expires=" + d.toUTCString() + ";";
      const path = "path=/;";
      var domain = "domain=" + "app.greenn.club" + ";";

      document.cookie = assign + expires + path + domain;
      if (process.env.VUE_APP_TYPE === "DEV") {
        this.srcIframe =
          "http://localhost:8080/login-to-google/" +
          Cookies.get("auth_greennCourse");
      } else {
        this.srcIframe =
          "https://app.greenn.club/login-to-google/" +
          Cookies.get("auth_greennCourse");
      }
      setTimeout(() => {
        this.openIntFrame = true;
        this.openIntegracaoGoogle();
      }, 1000);
    },
    removeLogin() {
      this.connectGoogleId = null;
      this.connectVimeoId = null;
      this.VimeoConnections = [];
      this.GoogleConnections = [];
      this.getConnections("vimeo");
      this.getConnections("google");
    },
    openIntegracaoVimeo() {
      clearInterval(interval);
      let interval = setInterval(() => {
        this.getConnections("vimeo");
      }, 5000);
    },
    openIntegracaoGoogle() {
      clearInterval(interval);
      let interval = setInterval(() => {
        this.getConnections("google");
      }, 5000);
    },
    updateBlocked(midia) {
      //console.log("midiaaaaaaaaaaaaaa", midia);
      if (midia.protected === 0 || midia.protected === false) {
        var result = 0;
      } else {
        var result = 1;
      }

      let data = {
        id: midia.id,
        title: midia.title,
        protected: result,
      };
      this.$root.$emit("loadOn");
      serviceMedias
        .postID(data)
        .then((resp) => {
          //console.log("update midia protection", resp);
          if (resp.protected === 0 || resp.protected === false) {
            resp.protected = false;
          } else {
            resp.protected = true;
          }
          this.midiaSelectedModal = resp;
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    blockLesson(media) {
      //console.log(media);
      if (media.block_pdf === true) {
        media.block_pdf = false;
      } else {
        media.block_pdf = true;
      }
    },
    selectPageMedia(to) {
      if (to === "next") {
        if (this.pageMedia == this.lastpageMedia) {
        } else {
          this.pageMedia++;
          this.getMidiasNotConnection("");
          return;
        }
      } else {
        if (this.pageMedia === 1) {
        } else {
          this.pageMedia--;
          this.getMidiasNotConnection("");
          return;
        }
      }
    },
    selectPageYt(to) {
      if (to === "next") {
        if (this.nextPageToken === null) {
        } else {
          this.toPageToken = this.nextPageToken;
          this.getVideoYt(this.nextPageToken);
          return;
        }
      } else {
        if (this.prevPageToken === null) {
        } else {
          this.toPageToken = this.nextPageToken;
          this.getVideoYt(this.prevPageToken);
          return;
        }
      }
    },
    getVideoYt(page) {
      if (page === undefined || page === null) {
        var url =
          "?type=google&connection_id=" +
          this.connectGoogleId +
          "&search=" +
          this.searchVideo;
      } else if (page === "search") {
        var url =
          "?type=google&connection_id=" +
          this.connectGoogleId +
          "&pageToken=" +
          this.toPageToken +
          "&search=" +
          this.searchVideo;
      } else {
        var url =
          "?type=google&connection_id=" +
          this.connectGoogleId +
          "&pageToken=" +
          page +
          "&search=" +
          this.searchVideo;
      }
      this.$root.$emit("loadOn");
      serviceMedias
        .read(url)
        .then((resp) => {
          //console.log("medias google connection search", resp);
          this.MidiasGoogle = resp.items;
          this.nextPageToken = resp.nextPageToken;
          this.prevPageToken = resp.prevPageToken;
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    selectPageVimeo(to) {
      if (to === "next") {
        if (this.pageVimeo == this.lastPageVimeo) {
        } else {
          this.pageVimeo++;
          this.getVideoVimeo();
          return;
        }
      } else {
        if (this.pageVimeo === 1) {
        } else {
          this.pageVimeo--;
          this.getVideoVimeo();
          return;
        }
      }
    },
    getVideoVimeo() {
      if (this.pageVimeo === 1 || this.pageVimeo === null) {
        var url =
          "?type=vimeo&connection_id=" +
          this.connectVimeoId +
          "&search=" +
          this.searchVideo;
      } else {
        var url =
          "?type=vimeo&connection_id=" +
          this.connectVimeoId +
          "&search=" +
          this.searchVideo +
          "&page=" +
          this.pageVimeo;
      }
      this.$root.$emit("loadOn");
      serviceMedias
        .read(url)
        .then((resp) => {
          //console.log("medias vimeo connection search", resp);
          this.MidiasVimeo = resp.data;
          var lastPageVimeo = resp.paging.last;
          var myParamPage = lastPageVimeo.split("page=")[1];
          this.lastPageVimeo = myParamPage;
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    addUrlVimeo() {
      let datato = this.typeToUrl;
      if (datato === "uploadintroducao") {
        this.$root.$emit("midiaintroducaovimeo", this.midiaSelectedModal);
      } else if (datato === "uploadlesson") {
        this.$root.$emit("midialessonvimeo", this.midiaSelectedModal);
      } else if (datato === "videotermos") {
        this.$root.$emit("midiatermovimeo", this.midiaSelectedModal);
      }
      this.closeModalItem();
      this.$root.$emit("bv::hide::modal", "modal-upload", "#btnShow");
    },
    selectUrlVimeo(item) {
      item.mime = "vimeo";
      this.openModalMidia = true;
      this.midiaSelectedModal = item;
      //console.log(item);
      setTimeout(() => {
        this.getPlayer();
      }, 1000);
    },
    addUrlPanda() {
      let datato = this.typeToUrl;
      console.log('this.midiaSelectedModal', this.midiaSelectedModal);
      if(this.midiaSelectedModal.cdn_url === null){
        this.midiaSelectedModal.cdn_url = 'panda'
      }
      if (datato === "uploadintroducao") {
        this.$root.$emit("midiaintroducaopanda", this.midiaSelectedModal);
      } else if (datato === "uploadlesson") {
        this.$root.$emit("midialessonpanda", this.midiaSelectedModal);
      } else if (datato === "videotermos") {
        this.$root.$emit("midiatermopanda", this.midiaSelectedModal);
      }
      this.closeModalItem();
      this.$root.$emit("bv::hide::modal", "modal-upload", "#btnShow");
    },
    addUrlYoutube() {
      let datato = this.typeToUrl;
      if (datato === "uploadintroducao") {
        this.$root.$emit("midiaintroducaoyt", this.midiaSelectedModal);
      } else if (datato === "uploadlesson") {
        this.$root.$emit("midialessonyt", this.midiaSelectedModal);
      } else if (datato === "videotermos") {
        this.$root.$emit("midiatermoyt", this.midiaSelectedModal);
      }
      this.closeModalItem();
      this.$root.$emit("bv::hide::modal", "modal-upload", "#btnShow");
    },
    selectUrlYoutube(item) {
      item.mime = "youtube";
      this.openModalMidia = true;
      this.midiaSelectedModal = item;
      //console.log(item);
      setTimeout(() => {
        this.getPlayer();
      }, 1000);
    },
    getPlayer() {
      this.player = videojs(
        this.$refs.videoPlayer,
        this.options,
        function onPlayerReady() {
          //console.log("onPlayerReady", this);
        }
      );
      //console.log("xxx", this.player);
    },
    selectGoogleConnect(id_connection) {
      this.$root.$emit("loadOn");
      serviceMedias
        .read("?type=google&connection_id=" + id_connection)
        .then((resp) => {
          this.connectGoogleId = id_connection;
          //console.log("medias google connection", resp);
          this.MidiasGoogle = resp.items;
          this.openIntFrame = false;
          this.nextPageToken = resp.nextPageToken;
          this.prevPageToken = resp.prevPageToken;
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    selectVimeoConnect(id_connection) {
      this.$root.$emit("loadOn");
      serviceMedias
        .read("?type=vimeo&connection_id=" + id_connection)
        .then((resp) => {
          this.connectVimeoId = id_connection;
          //console.log("medias vimeo connection", resp);
          this.MidiasVimeo = resp.data;
          var lastPageVimeo = resp.paging.last;
          var myParamPage = lastPageVimeo.split("page=")[1];
          this.lastPageVimeo = myParamPage;
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    openLinkIntegrationVimeo() {
      this.openIntegracaoVimeo();
      this.$root.$emit("loadOn");
      serviceConnection
        .read("vimeoUrl")
        .then((resp) => {
          //console.log("get url vimeo auth", resp);
          this.vimeoUrlConnection = resp.url;
          window.open(resp.url);
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    closeModalItem() {
      this.idMidia = "";
      this.idMidiaUrl = "";
      this.midiaSelectedModal = null;
      this.openModalMidia = false;
    },
    selectMidiasUploaded(media) {
      if (!media) {
        return;
      }
      //console.log("xxxxxxxxxxxxxxxxx media selected", media);
      // if (media.status === "DRAFT" || media.status === "UPLOADING") {
      //   notify(
      //     "erro",
      //     "O vídeo ainda está sendo processado, em breve estará disponível!"
      //   );
      // }
      this.idMidia = media.id;
      this.idMidiaUrl = media.cdn_url;
      this.idMidiaType = media.mime;
      this.idMidiaTitle = media.title;
      if (media.protected === 0) {
        media.protected = false;
      } else {
        media.protected = true;
      }

      this.midiaSelectedModal = media;
      this.openModalMidia = true;
    },
    SelectMidia() {
      let datato = this.typeToUrl;

      //console.log("chegou aq", datato);
      if (datato === "uploadcapa") {
        this.$root.$emit("midiacapa", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      } else if (datato === "uploadlessonadditional") {
        this.$root.$emit("midiaadditional", {
          id: this.idMidia,
          url: this.idMidiaUrl,
          type: this.idMidiaType,
        });
      } else if (datato === "uploadlesson") {
        this.$root.$emit("uploadlessonnew", {
          id: this.idMidia,
          url: this.idMidiaUrl,
          type: this.idMidiaType,
          title: this.idMidiaTitle,
        });
      } else if (datato === "picprofile") {
        this.$root.$emit("newpicprofile", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      } else if (datato === "logopainel") {
        this.$root.$emit("newlogopainel", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      } else if (datato === "uploadcapacard") {
        this.$root.$emit("newuploadcapacard", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      } else if (datato === "certify") {
        this.$root.$emit("midiacertify", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      } else if (datato === "pdfteste") {
        this.$root.$emit("midiapdfteste", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      } else if (datato === "uploadlessonthumb") {
        this.$root.$emit("uploadlessonthumbnew", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      } else if (datato === "logomarcapainel") {
        this.$root.$emit("newlogomarcapainel", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      } else if (datato === "backgroundlogin") {
        this.$root.$emit("newbackgroundlogin", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      } else if (datato === "backgroundpanel") {
        this.$root.$emit("newbackgroundpanel", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      } else if (datato === "uploadcapamodulo") {
        this.$root.$emit("newuploadcapamodulo", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      } else if (datato === "uploadcapaaudio") {
        this.$root.$emit("newuploadcapaaudio", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
        return;
      } else if (datato === "uploadbanner") {
        this.$root.$emit("newuploadbanner", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      } else if (datato === "uploadbannermobile") {
        this.$root.$emit("newuploadbannermobile", {
          id: this.idMidia,
          url: this.idMidiaUrl,
        });
      }

      if (this.midiaSelectedModal.use_custom_fields) {
        var result = [];
        let dados = this.customInputs;
        for (let i = 0; i < dados.length; i++) {
          const element = dados[i];
          result.push(element.text);
        }
        let data = {
          id: this.idMidia + `/meta/custom_fields`,
          value: result,
        };
        serviceMedias
          .postID(data)
          .then((resp) => {
            //console.log("update midia protection", resp);
          })
          .catch((err) => {
            //console.log(err);
          });
      }

      this.closeModalItem();
      this.$root.$emit("bv::hide::modal", "modal-upload", "#btnShow");
    },
    getMidiasNotConnection(searchFunc) {
      var dataUrl = "";
      if (searchFunc === "search") {
        dataUrl =
          "?type=media&search_string=" +
          this.searchMidias +
          "&mime=" +
          this.mediaTypeSelect;
      } else {
        dataUrl =
          "?type=media&page=" +
          this.pageMedia +
          "&mime=" +
          this.mediaTypeSelect;
      }
      serviceMedias
        .read(dataUrl)
        .then((resp) => {
          //console.log("medias uploadeds", resp);
          this.AllMedias = resp;

          this.pageMedia = resp.current_page;
          this.lastPageMedia = resp.last_page;
          this.updatePanda();
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    tabs(active) {
      this.pageMedia = 1;
      this.searchMidias = "";
      if (active === "media") {
        this.media = true;
        this.enviar = false;
        this.youtube = false;
        this.vimeo = false;
        this.embed = false;
        this.linkvideo = false;
        this.enviarpanda = false;
        this.files = [];
        this.getMidiasNotConnection("");
      } else if (active === "enviar") {
        this.media = false;
        this.enviar = true;
        this.youtube = false;
        this.embed = false;
        this.vimeo = false;
        this.linkvideo = false;
        this.enviarpanda = false;
        this.files = [];
        this.getConnections("vimeo");
      } else if (active === "enviarpanda") {
        this.media = false;
        this.enviar = false;
        this.youtube = false;
        this.embed = false;
        this.vimeo = false;
        this.linkvideo = false;
        this.enviarpanda = true;
        this.verifyResource();
        this.getConnections("vimeo");
      } else if (active === "youtube") {
        this.media = false;
        this.enviar = false;
        this.embed = false;
        this.youtube = true;
        this.vimeo = false;
        this.linkvideo = false;
        this.enviarpanda = false;
        this.files = [];
        this.getConnections("google");
      } else if (active === "vimeo") {
        this.media = false;
        this.enviar = false;
        this.youtube = false;
        this.vimeo = true;
        this.embed = false;
        this.linkvideo = false;
        this.enviarpanda = false;
        this.files = [];
        this.getConnections("vimeo");
      } else if (active === "linkvideo") {
        this.media = false;
        this.enviar = false;
        this.youtube = false;
        this.vimeo = false;
        this.linkvideo = true;
        this.enviarpanda = false;
        this.files = [];
      }
      this.openIntFrame = false;
    },
    getConnections(type) {
      serviceConnection
        .search2("type=" + type)
        .then((resp) => {
          //console.log("connections modal by type: " + type, resp);
          if (type === "google") {
            this.GoogleConnections = resp;
          } else if (type === "vimeo") {
            this.VimeoConnections = resp;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    selectThumb(id) {
      this.$refs.fileInput.click();
      this.videoId = id;
    },
    handleFileSelection(e) {
      const file = e.target.files[0];
      const formData  = new FormData();
      formData.append('file', file);
      const headers = { Authorization: Cookies.get("auth_greennCourse") };
      var config = {
        method: "post",
        url: this.returnApi() + `/panda/thumbs/${this.videoId}`, formData,
        headers: headers,
        data: formData,
      };
      axios(config)
        .then(response => {
          notify('sucesso', 'Thumbnail enviada com sucesso. <br> Sua thumbnail está sendo processada pelo sistema de streaming. <br> Em breve será atualizada!', 10000);
          
        })
        .catch(error => {
          notify("erro", "Erro ao atualizar a Thumnail");
        }); 
      }
    },
  mounted() {
    this.$root.$on("statusUpload", (data) => {
      var index = data[0];
      //console.log("change status", data);
      var teste = this.files;
      
      if (teste[index]) {
        teste[index].textstatus = data[1];
      }

      this.files = [];

      //console.log("files", this.files);
      //console.log("teste", teste);

      this.files = teste;
    });
    this.$root.$on("uploadcapa", (data) => {
      this.typeToUrl = "uploadcapa";
      this.enviar = true;
      this.media = false;
      this.youtube = false;
      this.linkvideo = false;
      this.allItens = false;

      this.vimeo = false;
      this.embed = false;
      this.enviarpanda = false;
      this.files = [];
      this.notLinks = "notlinks";
      this.getCustoms();
      this.mediaTypeSelect = "image";
      this.getPorcentage();
    });
    this.$root.$on("uploadcapamodulo", (data) => {
      this.typeToUrl = "uploadcapamodulo";
      this.enviar = true;
      this.media = false;
      this.youtube = false;
      this.allItens = false;

      this.linkvideo = false;
      this.enviarpanda = false;
      this.files = [];
      this.vimeo = false;
      this.embed = false;
      this.notLinks = "notlinks";
      this.getCustoms();
      this.getPorcentage();
      this.mediaTypeSelect = "image";
    });
    this.$root.$on("uploadcapaaudio", (data) => {
      this.mediaTypeSelect = "image";
      this.typeToUrl = "uploadcapaaudio";
      this.enviar = true;
      this.media = false;
      this.youtube = false;
      this.allItens = false;

      this.linkvideo = false;
      this.enviarpanda = false;
      this.files = [];
      this.vimeo = false;
      this.embed = false;
      this.notLinks = "notlinks";
      this.getCustoms();
      this.getPorcentage();
    });
    this.$root.$on("uploadbanner", (data) => {
      this.typeToUrl = "uploadbanner";
      this.enviar = true;
      this.media = false;
      this.youtube = false;
      this.linkvideo = false;
      this.allItens = false;

      this.enviarpanda = false;
      this.files = [];
      this.vimeo = false;
      this.embed = false;
      this.notLinks = "notlinks";
      this.getCustoms();
      this.mediaTypeSelect = "image";
      this.getPorcentage();
    });
    this.$root.$on("uploadbannermobile", (data) => {
      this.typeToUrl = "uploadbannermobile";
      this.enviar = true;
      this.media = false;
      this.youtube = false;
      this.linkvideo = false;
      this.allItens = false;

      this.enviarpanda = false;
      this.files = [];
      this.vimeo = false;
      this.embed = false;
      this.notLinks = "notlinks";
      this.getCustoms();
      this.mediaTypeSelect = "image";
      this.getPorcentage();
    });
    this.$root.$on("uploadintroducao", (data) => {
      this.typeToUrl = "uploadintroducao";
      this.enviar = false;
      this.media = false;
      this.youtube = true;
      this.allItens = false;

      this.linkvideo = false;
      this.enviarpanda = false;
      this.vimeo = false;
      this.embed = false;
      this.verifyResource();
      this.notLinks = "integrations";
      this.mediaTypeSelect = "video";
      this.getPorcentage();
    });
    this.$root.$on("uploadlesson", (data) => {
      if (data === 'audio') {
        this.mediaTypeSelect = "audio";
      }else{
        this.mediaTypeSelect = "video";
      }
      this.typeToUrl = "uploadlesson";
      this.enviar = false;
      this.media = false;
      this.youtube = false;
      this.linkvideo = false;
      this.enviarpanda = true;
      this.vimeo = false;
      this.allItens = false;

      this.embed = false;
      this.verifyResource();
      this.notLinks = "links";
      this.selectedLink = "vimeo";
      this.midiaEmbedYt = "";
      this.midiaEmbedVimeo = "";
      this.midiaEmbedIframe = "";
      this.mediaTypeSelect = "video";
      this.getPorcentage();
    });
    this.$root.$on("uploadlessonitem", (data) => {
      this.typeToUrl = "uploadlesson";
      this.enviar = true;
      this.media = false;
      this.youtube = false;
      this.linkvideo = false;
      this.vimeo = false;
      this.enviarpanda = false;
      this.files = [];
      this.allItens = false;

      this.embed = false;
      this.notLinks = "links";
      this.selectedLink = "vimeo";
      this.midiaEmbedYt = "";
      this.midiaEmbedVimeo = "";
      this.midiaEmbedIframe = "";
      this.selectedLink = data.type;
      if (data.type === "youtube") {
        this.midiaEmbedYt = data.link;
      } else if (data.type === "iframe") {
        this.midiaEmbedIframe = data.link;
      } else {
        this.midiaEmbedVimeo = data.link;
      }
      this.getCustoms();
      this.mediaTypeSelect = "video";
      this.getPorcentage();
    });
    this.$root.$on("newuploadcapaaudio", (data) => {
      //console.log("xxxxx", data);
      this.midiaSelectedModal = this.midiaSelectedModal2;
      this.midiaSelectedModal.cover = data.url;
      this.selectMidiasUploaded(this.midiaSelectedModal);
      this.typeToUrl = "uploadlesson";
      let data2 = {
        id: this.midiaSelectedModal.id + `/meta/cover_id`,
        value: data.id,
      };

      serviceMedias
        .postID(data2)
        .then((resp) => {
          //console.log("update midia audio image", resp);
        })
        .catch((err) => {
          //console.log(err);
        });
    });
    this.$root.$on("uploadlessonadditional", (data) => {
      this.typeToUrl = "uploadlessonadditional";
      this.enviar = true;
      this.media = false;
      this.youtube = false;
      this.vimeo = false;
      this.enviarpanda = false;
      this.files = [];
      this.linkvideo = false;
      this.embed = false;
      this.notLinks = "notlinks";
      this.getPorcentage();

      this.getCustoms();
      this.mediaTypeSelect = "application";
      this.allItens = true;
    });
    this.$root.$on("returngoogle", (data) => {
      this.getConnections("google");
    });
    this.$root.$on("picprofile", (data) => {
      this.typeToUrl = "picprofile";
      this.enviar = true;
      this.media = false;
      this.youtube = false;
      this.linkvideo = false;
      this.enviarpanda = false;
      this.files = [];
      this.vimeo = false;
      this.getPorcentage();

      this.embed = false;
      this.notLinks = "notlinks";
      this.getCustoms();
      this.mediaTypeSelect = "image";
      this.allItens = false;
    });
    this.$root.$on("backgroundlogin", (data) => {
      this.typeToUrl = "backgroundlogin";
      this.enviar = true;
      this.media = false;
      this.youtube = false;
      this.linkvideo = false;
      this.vimeo = false;
      this.enviarpanda = false;
      this.files = [];
      this.embed = false;
      this.getPorcentage();

      this.notLinks = "notlinks";
      this.getCustoms();
      this.mediaTypeSelect = "image";
      this.allItens = false;
    });
    this.$root.$on("backgroundpanel", (data) => {
      this.typeToUrl = "backgroundpanel";
      this.enviar = true;
      this.media = false;
      this.youtube = false;
      this.linkvideo = false;
      this.vimeo = false;
      this.enviarpanda = false;
      this.files = [];
      this.embed = false;
      this.getPorcentage();

      this.notLinks = "notlinks";
      this.getCustoms();
      this.mediaTypeSelect = "image";
      this.allItens = false;
    });
    this.$root.$on("pdfteste", (data) => {
      this.typeToUrl = "pdfteste";
      this.enviar = true;
      this.media = false;
      this.youtube = false;
      this.linkvideo = false;
      this.vimeo = false;
      this.getPorcentage();

      this.enviarpanda = false;
      this.files = [];
      this.embed = false;
      this.notLinks = "notlinks";
      this.getCustoms();
      this.mediaTypeSelect = "application";
      this.allItens = false;
    });
    this.$root.$on("logopainel", (data) => {
      this.typeToUrl = "logopainel";
      this.enviar = true;
      this.media = false;
      this.youtube = false;
      this.linkvideo = false;
      this.vimeo = false;
      this.enviarpanda = false;
      this.files = [];
      this.getPorcentage();

      this.embed = false;
      this.notLinks = "notlinks";
      this.getCustoms();
      this.mediaTypeSelect = "image";
      this.allItens = false;
    });
    this.$root.$on("logomarcapainel", (data) => {
      this.typeToUrl = "logomarcapainel";
      this.enviar = true;
      this.media = false;
      this.youtube = false;
      this.linkvideo = false;
      this.enviarpanda = false;
      this.getPorcentage();

      this.files = [];
      this.vimeo = false;
      this.embed = false;
      this.notLinks = "notlinks";
      this.getCustoms();
      this.mediaTypeSelect = "image";
      this.allItens = false;
    });
    this.$root.$on("uploadcapacard", (data) => {
      this.typeToUrl = "uploadcapacard";
      this.enviar = true;
      this.media = false;
      this.youtube = false;
      this.linkvideo = false;
      this.enviarpanda = false;
      this.files = [];
      this.vimeo = false;
      this.embed = false;
      this.notLinks = "notlinks";
      this.getPorcentage();

      this.getCustoms();
      this.mediaTypeSelect = "image";
      this.allItens = false;
    });
    this.$root.$on("certify", (data) => {
      this.typeToUrl = "certify";
      this.enviar = true;
      this.media = false;
      this.youtube = false;
      this.enviarpanda = false;
      this.files = [];
      this.getPorcentage();

      this.linkvideo = false;
      this.vimeo = false;
      this.embed = false;
      this.notLinks = "notlinks";
      this.getCustoms();
      this.mediaTypeSelect = "image";
      this.allItens = false;
    });
    this.$root.$on("uploadlessonthumb", (data) => {
      this.typeToUrl = "uploadlessonthumb";
      this.enviar = true;
      this.media = false;
      this.youtube = false;
      this.linkvideo = false;
      this.enviarpanda = false;
      this.files = [];
      this.getPorcentage();

      this.vimeo = false;
      this.embed = false;
      this.notLinks = "notlinks";
      this.getCustoms();
      this.mediaTypeSelect = "image";
      this.allItens = false;
    });
    this.$root.$on("midiaUploadedSucess", (data) => {
      //console.log("modal upload event", data);
      this.tabs("media");
      //   this.selectMidiasUploaded(data.response);
    });
    this.$root.$on("videotermos", (data) => {
      this.typeToUrl = "videotermos";
      this.enviar = false;
      this.enviarpanda = true;
      this.getPorcentage();

      this.media = false;
      this.youtube = false;
      this.allItens = false;

      this.linkvideo = false;
      this.vimeo = false;
      this.embed = false;
      this.notLinks = "links";
      this.getConnections("google");
      this.mediaTypeSelect = "video";
      this.verifyResource();
    });
    this.$root.$on("opengoogleint", (data) => {
      this.openIntegracaoGoogle();
    });
  },
};
</script>
<style lang="scss">
fieldset[disabled] .multiselect {
  pointer-events: none;
}
.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: #fff;
  display: block;
}
.multiselect__spinner:after,
.multiselect__spinner:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 2px solid transparent;
  border-top-color: #41b883;
  box-shadow: 0 0 0 1px transparent;
}
.multiselect__spinner:before {
  animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation-iteration-count: infinite;
}
.multiselect__spinner:after {
  animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation-iteration-count: infinite;
}
.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}
.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}
.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 16px;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e;
}
.multiselect * {
  box-sizing: border-box;
}
.multiselect:focus {
  outline: none;
}
.multiselect--disabled {
  background: #ededed;
  pointer-events: none;
  opacity: 0.6;
}
.multiselect--active {
  z-index: 50;
}
.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.multiselect--active .multiselect__select {
  transform: rotate(180deg);
}
.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 5px;
  background: #fff;
  padding: 0 0 0 5px;
  width: 100%;
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
  vertical-align: top;
}
.multiselect__input:-ms-input-placeholder {
  color: #35495e;
}
.multiselect__input::placeholder {
  color: #35495e;
}
.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}
.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}
.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none;
}
.multiselect__single {
  padding-left: 5px;
  margin-bottom: 8px;
}
.multiselect__tags-wrap {
  display: inline;
}
.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}
.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: #41b883;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: normal;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all 0.2s ease;
  border-radius: 5px;
}
.multiselect__tag-icon:after {
  content: "\D7";
  color: #266d4d;
  font-size: 14px;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #369a6e;
}
.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: #fff;
}
.multiselect__current {
  min-height: 40px;
  overflow: hidden;
  padding: 8px 30px 0 12px;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
}
.multiselect__current,
.multiselect__select {
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
}
.multiselect__select {
  position: absolute;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease;
}
.multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: "";
}
.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
.multiselect--active .multiselect__placeholder {
  display: none;
}
.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 50;
  -webkit-overflow-scrolling: touch;
}
.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}
.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}
.multiselect__content::webkit-scrollbar {
  display: none;
}
.multiselect__element {
  display: block;
}
.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
}
.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 13px;
}
.multiselect__option--highlight {
  background: #41b883;
  outline: none;
  color: #fff;
}
.multiselect__option--highlight:after {
  content: attr(data-select);
  background: #41b883;
  color: #fff;
}
.multiselect__option--selected {
  background: #f3f3f3;
  color: #35495e;
  font-weight: 700;
}
.multiselect__option--selected:after {
  content: attr(data-selected);
  color: silver;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}
.multiselect__option--selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: #ededed;
  color: #a6a6a6;
}
.multiselect__option--disabled {
  background: #ededed !important;
  color: #a6a6a6 !important;
  cursor: text;
  pointer-events: none;
}
.multiselect__option--group {
  background: #ededed;
  color: #35495e;
}
.multiselect__option--group.multiselect__option--highlight {
  background: #35495e;
  color: #fff;
}
.multiselect__option--group.multiselect__option--highlight:after {
  background: #35495e;
}
.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede;
}
.multiselect__option--group-selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}
.multiselect__option--group-selected.multiselect__option--highlight:after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}
.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.15s ease;
}
.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}
.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}
[dir="rtl"] .multiselect {
  text-align: right;
}
[dir="rtl"] .multiselect__select {
  right: auto;
  left: 1px;
}
[dir="rtl"] .multiselect__tags {
  padding: 8px 8px 0 40px;
}
[dir="rtl"] .multiselect__content {
  text-align: right;
}
[dir="rtl"] .multiselect__option:after {
  right: auto;
  left: 0;
}
[dir="rtl"] .multiselect__clear {
  right: auto;
  left: 12px;
}
[dir="rtl"] .multiselect__spinner {
  right: auto;
  left: 1px;
}
@keyframes spinning {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}
.spaceMembersMobile {
  width: 100% !important;
  padding: 70px 10px 0px 27px !important;
  .table tr.row-table-body {
    line-height: 40px !important;
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
}
#modal-upload .tabs-upload.active .text {
  color: var(--maincolor);
}
#modal-upload .tabs-upload.active path {
  stroke: var(--maincolor) !important;
}
#modal-upload {
  background: var(--fundomodal) !important;
  .tosearch {
    .inputSearch {
      position: relative;
      margin-left: 20px;
      margin-bottom: 20px;
      width: 450px;
    }
    .input-busca {
      width: 100%;
      height: 60px;
      background: #ffffff;
      border: solid 1px var(--bordercolor);
      border-radius: 5px;
      padding: 20px 30px;
      font-family: Montserrat;
      font-weight: normal;
      color: #81858e;
      font-size: 14px;
      transition: 0.3s;
    }
    .input-busca:hover,
    .input-busca:focus {
      box-shadow: 0 6px 12px rgba(30, 60, 90, 0.2);
    }
    .search {
      position: absolute;
      right: 25px;
      top: 24px;
      width: 14.7px;
      height: 14.7px;
      z-index: 999;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      cursor: pointer;
    }
  }
  .modalLateralMidiaFundo {
    position: fixed;
    background: var(--fundomodal);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999999;
    .spaceVideoModal {
      overflow: hidden;
      border-radius: 5px;
      display: flex;
      justify-content: center;
    }
    .spaceImgModal {
      height: 25vh;
      overflow: hidden;
      border-radius: 5px;
      display: flex;
      justify-content: center;
    }
    label {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: var(--fontcolor2);
    }
    img.imgMidia {
      // width: 100%;
      border-radius: 5px;
    }
    .textMidiaSelected {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #81858e;
    }
    .spaceClose {
      display: flex;
      justify-content: flex-end;
      svg {
        filter: var(--filtericon);
        cursor: pointer;
      }
    }
    .modalLateralMidia {
      z-index: 99999999;
      position: absolute;
      height: 100%;
      width: 500px;
      top: 0px;
      background: var(--backgroundcolor);
      right: 0px;
      border-radius: 3px 0px 0px 3px;
      padding: 25px;
      overflow: auto;
    }
  }
  .spacePdfLock {
    display: flex;
    .block-pdf {
      position: absolute;
      width: 32px;
      margin-top: -13px;
      margin-left: -12px;
    }
  }
  .gridListen {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
  }
  .gridListenImages {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 12px;
    height: 43vh;
    overflow: auto;
  }
  .modal-dialog.modal-xl {
    width: 98% !important;
    max-width: none !important;
    height: 100% !important;
    margin: auto !important;
    align-items: center;
    display: flex;
  }
  .close {
    color: var(--icon) !important;
  }
  .modal-content {
    background: var(--backgroundcolor) !important;
    height: 96% !important;
  }
  .paginacao {
    width: 100%;
    justify-content: space-around;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    .txtPaginacao {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: var(--fontcolor);
      cursor: pointer;
    }
    .txtPaginacao:hover {
      color: var(--maincolor);
    }
  }
  .listYt {
    cursor: pointer;
    display: flex;
    border: none;
    img.imgVideo {
      max-width: 175px;
      width: 145px;
      max-height: 80px;
      height: 80px;
    }
  }
  #modal-vimeo {
    .modal-header {
      border: none !important;
    }
  }
  textarea.textareaEmbed.form-control {
    width: 80% !important;
    min-height: 10em;
  }
  .listLinksVideos::-webkit-scrollbar {
    background-color: var(--backgroundcolor);
    width: 2px;
  }

  .listLinksVideos::-webkit-scrollbar-track {
    background-color: #717171;
  }

  .listLinksVideos::-webkit-scrollbar-thumb {
    background-color: #00000075;
    border-radius: 31px;
  }

  .listLinksVideos::-webkit-scrollbar-button {
    display: none;
  }
  .listLinksVideos {
    margin-top: 30px;
    width: 100%;
    height: 51vh;
    overflow: auto;
    overflow-x: hidden;
    .inputSearch {
      position: relative;
      margin-left: 20px;
      margin-bottom: 20px;
      width: 450px;
    }
    .input-busca {
      width: 100%;
      height: 60px;
      background: #ffffff;
      border: solid 1px var(--bordercolor);
      border-radius: 5px;
      padding: 20px 30px;
      font-family: Montserrat;
      font-weight: normal;
      color: #81858e;
      font-size: 14px;
      transition: 0.3s;
    }
    .input-busca:hover,
    .input-busca:focus {
      box-shadow: 0 6px 12px rgba(30, 60, 90, 0.2);
    }
    .search {
      position: absolute;
      right: 25px;
      top: 24px;
      width: 14.7px;
      height: 14.7px;
      z-index: 999;
      -webkit-transition: 0.3s;
      transition: 0.3s;
    }
    .listIntegration {
      width: 50%;
      margin-bottom: 20px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      border: solid 1px var(--bordercolor);
      color: var(--fontcolor);
      transform: translateY(0px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
    }
    .listIntegration:hover {
      border: 1px solid var(--maincolor);
      color: var(--maincolor);
      transform: translateY(2px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
    }
    .listIntegrationYt {
      width: 50%;
      margin-bottom: 20px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      border: solid 1px var(--bordercolor);
      color: var(--fontcolor);
      transform: translateY(0px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
    }
    .listIntegrationYt:hover {
      border: 1px solid#2133D2;
      color: #2133d2;
      transform: translateY(2px);
      transition: transform 0.44s ease, box-shadow 0.44s ease;
    }
    .btnIntegraçãoVimeo {
      border: none;
      background: var(--maincolortrans);
      box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
      border-radius: 5px;
      width: 231.25px;
      height: 45px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: var(--maincolor);
    }
    .btnIntegração {
      border: none;
      background: rgba(33, 51, 210, 0.1);
      box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
      border-radius: 5px;
      width: 231.25px;
      height: 45px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: #2133d2;
    }
    select {
      border: 1px solid var(--bordercolor);
      box-sizing: border-box;
      border-radius: 5px;
      height: 55px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #6d767e;
      overflow: hidden !important;
      resize: none !important;
    }
    input,
    textarea {
      border: 1px solid var(--bordercolor);
      box-sizing: border-box;
      border-radius: 5px;
      height: 55px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
      overflow: hidden !important;
      resize: none !important;
      background: white;
    }
    input:hover,
    select:hover,
    textarea:hover,
    input:focus,
    select:focus,
    textarea:focus {
      border: 1px solid var(--maincolor);
      box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #000000;
    }
    label {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: var(--fontcolor2);
    }
  }
  .hide {
    display: none;
  }
  .positionx {
    position: absolute;
    transition: 1s ease-in;
    margin-left: 5vw;
    margin-top: 50px;
  }
  iframe.imagem {
    pointer-events: none;
    border-radius: 3px;
    overflow: hidden;
    width: 100%;
  }
  .filter {
    filter: grayscale(100%);
  }
  .imagem {
    border-radius: 3px;
    overflow: hidden;
    height: fit-content;
    // width: 100%;
  }
  .allmedia {
    cursor: pointer;
    z-index: 99;
    height: 154px;
    overflow: hidden;
    justify-content: center;
    border: solid 1px #ececec;
    border-radius: 5px;
    padding: 7px;
  }
  .media {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 36vh;
    overflow: auto;
    .box {
      box-sizing: border-box;
      padding: 1rem;
    }
  }
  .tabs-upload {
    display: flex;
    cursor: pointer;
    margin-right: 2em;
    .text {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: var(--fontcolor);
    }
    .text2 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: #b5b9c5;
    }
    svg {
      margin-right: 10px;
    }
    .yt {
      position: relative;
      top: 4px;
    }
    .vimeo {
      position: relative;
      top: 3px;
    }
  }
  .upload {
    .btn-upload {
      background: var(--maincolor);
      border-radius: 5px;
      height: 55px;
      border: none;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #ffffff;
    }
    .btn-delete {
      background: #FF0C37;
      border-radius: 5px;
      height: 55px;
      border: none;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #ffffff;
    }
    .lineDiv {
      height: 0.5px;
      background: #e5e5e5;
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
    .title {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      color: var(--fontcolor) !important;
    }
    .desc {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: #81858e;
    }
  }
  .tabs-upload:hover {
    path {
      stroke: var(--maincolor) !important;
    }
    .text {
      color: var(--maincolor) !important;
    }
  }
  .tabs-upload .active {
    path {
      stroke: var(--maincolor) !important;
    }
    .text {
      color: var(--maincolor) !important;
    }
  }
  .modal-header {
    border: none !important;
  }
}

.flexHeader {
  display: flex;
  justify-content: space-between;
  select {
    border: 1px solid var(--bordercolor);
    box-sizing: border-box;
    border-radius: 5px;
    height: 45px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #6d767e;
    overflow: hidden !important;
    resize: none !important;
  }
  select:focus {
    border: 1px solid var(--maincolor);
  }
}

.titleVideo {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--fontcolor);
  margin-left: 15px;
  padding-top: 10px;
}

input.arquivo-input {
  height: 35vh !important;
  z-index: 9999;
  opacity: 0;
  cursor: pointer;
}

.drag-drop {
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 9vh 30px;
  position: absolute;
  top: 0;
  z-index: -1;
}
.drag-drop .escolher-file {
  background: rgba(33, 51, 210, 0.2);
  color: #2133d2;
  border: none;
  padding: 0 25px !important;
  height: 40px;
  font-size: 13px;
  margin-top: 20px;
}
.lista-entrega ul li .icon-lixeira {
  width: 14px;
  filter: invert(50%);
}
.icon-lixeira:hover {
  filter: invert(0) !important;
}
.arquivo-item {
  display: block;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  background: #f7f7f7;
  height: fit-content;
  border-radius: 5px;
  font-size: 15px;
  span {
    text-align: center;
  }
  .thumb {
    width: 100%;
    object-fit: cover;
  }
}
.arquivo-item + .arquivo-item {
  margin-top: 10px;
}
.arquivo-item .item-lixeira {
  width: 20px;
}
.arquivo-item img {
  cursor: pointer;
}

.testezindex {
  z-index: 999999;
}

.newText {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: var(--fontcolor);
}

.newTextDiv {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: var(--fontcolor);
  margin-top: 10px;
  margin-bottom: 10px;
}

.hideBox {
  display: none !important;
}

.newBtn {
  background: var(--maincolortrans);
  border: 2px dashed var(--maincolor);
  box-sizing: border-box;
  border-radius: 5px;
  height: 55px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--maincolor);
  padding-top: 17px;
  width: 292.85px;
}

.displayImages {
  display: grid;
  grid-template-columns: repeat(auto-fit, 190px);
  gap: 12px;
  height: 43vh;
  overflow: auto;
}

.displayImages tr,
.displayImages tr div {
  width: 100%;
}

.displayImages tr div div {
  width: 100%;
  cursor: pointer;
  overflow: hidden;
}

.displayImages tr div div img {
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
}

.imgMidiaAudio {
  height: 70%;
  margin: 0 auto;
}

.spaceImgModal {
  .imgMidiaAudio {
    height: 50% !important;
  }
}

.spaceInputs {
  margin-bottom: 30px;
  .textInfo {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #81858e;
    margin-top: 5px;
  }
  .btn-criar {
    background: var(--maincolor);
    border: 1px solid var(--maincolor);
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    border-radius: 5px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
    width: 100%;
    height: 55px;
    margin-top: 40px;
  }
  .btn-openModal {
    background: var(--maincolortrans);
    border: 2px dashed var(--maincolor);
    box-sizing: border-box;
    border-radius: 5px;
    height: 55px;
    cursor: pointer;
    .text {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: var(--maincolor);
      display: flex;
      justify-content: center;
      margin-top: 17px;
    }
  }
  .gerenciarCat {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.2px;
    color: var(--maincolor);
    position: absolute;
    right: 25em;
    cursor: pointer;
  }
  select {
    border: 1px solid var(--bordercolor);
    box-sizing: border-box;
    border-radius: 5px;
    height: 55px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #6d767e;
    overflow: hidden !important;
    resize: none !important;
  }
  input,
  textarea {
    border: 1px solid var(--bordercolor);
    box-sizing: border-box;
    border-radius: 5px;
    height: 55px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    overflow: hidden !important;
    resize: none !important;
    background: white;
  }
  input:hover,
  select:hover,
  textarea:hover,
  input:focus,
  select:focus,
  textarea:focus {
    border: 1px solid var(--maincolor);
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
  }
  label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--fontcolor);
  }
}

.name-audio {
  border: 1px solid var(--bordercolor);
  box-sizing: border-box;
  border-radius: 5px;
  height: 45px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: var(--fontcolor);
}

.change-title {
  height: 45px !important;
  float: right;
}

.w100 {
  width: 100% !important;
}
.btn-reenviar {
  background: rgba(33, 51, 210, 0.1);
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  border-radius: 5px;
  font-weight: 600;
  color: #2133d2;
  width: 100%;
  height: 55px;
  margin-top: 20px;
}
.divSelectThumb, .divSelectThumb:hover{
  text-decoration:none; 
  flex-direction: column;
  border: 1px dashed #ccc; 
  padding: 16px;
}
.selectThumb, .selectThumb:hover{
  text-decoration:none; 
  color:#000; 
  cursor: pointer; 
  align-self: center;
}
.centerThumb, .centerThumb:hover{
  text-decoration:none; 
  display: flex; 
  flex-direction: column; 
  align-items: center; 
  align-content: center;
}
.textThumb, .textThumb:hover{
  text-decoration:none; 
  display: flex; 
  flex-direction: column; 
  align-items: center; 
  align-content: center;
  margin-top: 8px;
}
.textThumbLow, .textThumbLow:hover{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #81858e; 
}
</style>