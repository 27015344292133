import Rest from '@/services/Rest';

/**
 * @typedef {ConnectionGoogleService}
 */
export default class ConnectionGoogleService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'connection/google/code'
}