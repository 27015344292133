<template>
  <button v-google-signin-button="clientId" @click="eventToOpen" class="google-signin-button">Fazer Integração</button>
</template>

<script>
import GoogleSignInButton from 'vue-google-signin-button-directive';
import ConnectionGoogleService from "@/services/resources/ConnectionGoogleService";

const serviceGoogle = ConnectionGoogleService.build();

export default {
  directives: {
    GoogleSignInButton
  },
  data: () => ({
    clientId: '753585437519-c6kn7emvogui3emd98ji8811m3eue21l.apps.googleusercontent.com'
  }),
  methods: {
    eventToOpen(){
      this.$root.$emit('opengoogleint');
    },
    OnGoogleAuthSuccess (idToken) {
        //console.log(idToken)
        let data = {
            'code':idToken
        }
        serviceGoogle
        .create(data)
        .then(resp => {
            //console.log("backend google api", resp);
            this.$root.$emit('returngoogle', resp);
        }).catch(err => {
            //console.log(err);
        }); 
    },
    OnGoogleAuthFail (error) {
      //console.log(error)
    }
  }
}
</script>

<style lang="scss" scoped>
.google-signin-button{
    border: none;
    background: rgba(33, 51, 210, 0.1);
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    border-radius: 5px;
    width: 231.25px;
    height: 45px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #2133D2;
}
</style>